import { Route, Routes } from 'react-router-dom';
import CompanyManagement from 'src/busines-pages/companies/CompanyManagement';
import CoreManagement from 'src/busines-pages/core/CoreManagement';
import OrderManagement from 'src/busines-pages/orders/OrderManagement';
import ProjectManagement from 'src/busines-pages/projects/ProjectManagement';
import NotFound from 'src/components/NotFound/NotFound';
import { useContext } from 'react';
import { ConfigContext } from 'src/providers/ConfigProvider';
import SauterDeliveryNote from '../busines-pages/SauterDeliveryNote';
import SauterDeliveryNotes from '../busines-pages/SauterDeliveryNotes';
import SimmaDeliveryNote from '../busines-pages/SimmaDeliveryNote';
import SimmaDeliveryNotes from '../busines-pages/SimmaDeliveryNotes';
import SimmaOrder from '../busines-pages/SimmaOrder';
import SimmaOrders from '../busines-pages/SimmaOrders';
import SupplierSelection from '../busines-pages/SupplierSelection';
import SupplierManagement from 'src/busines-pages/supplier/SupplierManagement';
import DsaPage from 'src/busines-pages/supplier/Dsa/DsaPage';
import ApiManagement from 'src/busines-pages/companies/ApiManagement';
import CompanyTabsSelection from 'src/busines-pages/companies/components/CompanyTabsSelection';
import GodelPage from 'src/busines-pages/supplier/Godel/GodelPage';

export const AppRouter = (): any => {
  const config = useContext(ConfigContext);

  return (
    <Routes>
      <Route
        path="/"
        element={
          config.supplierMockEnable !== 'supplier-mock-disable' ? (
            <SupplierSelection />
          ) : (
            <CompanyManagement />
          )
        }
      />
      {config.supplierMockEnable !== 'supplier-mock-disable' && (
        <Route path="/mocks" element={<SupplierSelection />} />
      )}
      <Route path='/company' element={<CompanyTabsSelection />} />
      <Route path='/company/operations' element={<CompanyManagement />} />
      <Route path='/company/powerbi-api' element={<ApiManagement />} />
      <Route path="/project" element={<ProjectManagement />} />
      <Route path="/refresh" element={<OrderManagement />} />
      <Route path="/core" element={<CoreManagement />} />
      <Route path="/supplier" element={<SupplierManagement />} />
      <Route path="/supplier/dsa" element={<DsaPage />} />
      <Route path="/supplier/godel" element={<GodelPage />} />

      <Route path="/mocks/simma/orders" element={<SimmaOrders />} />
      <Route
        path="/mocks/simma/order/:orderId/deliverynotes"
        element={<SimmaDeliveryNotes />}
      />
      <Route
        path="/mocks/simma/order/:orderId/deliverynote/:deliveryNote"
        element={<SimmaDeliveryNote createCustomDeliveryNotes={false} />}
      />
      <Route
        path="/mocks/simma/order/:orderId/deliverynotes/create"
        element={<SimmaDeliveryNote createCustomDeliveryNotes={true} />}
      />
      <Route path="/mocks/simma/order/:orderId" element={<SimmaOrder />} />

      <Route
        path="/mocks/sauter/order/:orderId/deliverynote/:deliveryNote"
        element={<SauterDeliveryNote createCustomDeliveryNotes={false} />}
      />
      <Route
        path="/mocks/sauter/order/:orderId/deliverynotes/create"
        element={<SauterDeliveryNote createCustomDeliveryNotes={true} />}
      />
      <Route
        path="/mocks/sauter/deliverynotes"
        element={<SauterDeliveryNotes />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRouter;
