import { Button, Card, Checkbox, Col, DatePicker, Divider, Form, Input, Row } from 'antd';
import { useContext, useState } from 'react';
import ErrorNotification from 'src/components/Notification/ErrorNotification';
import SuccessNotification from 'src/components/Notification/SuccessNotification';
import { ToolAdminContext } from 'src/providers/AdminToolProvider';

const CreateApiAccess = (): JSX.Element => {

    const [token, setToken] = useState('');
    const { operationalToolClient } = useContext(ToolAdminContext);
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [isClient, setIsClient] = useState(true);

    const handleCheckboxChange = (event: any) => {
        setIsClient(event.target.checked);
    };
    
    async function createApiAccess() {
        setIsLoading(true);
        try {
            const {
                organizationId,
                tokenExpirationDate,
            } = await form.validateFields();


            operationalToolClient.createApiAccess({ organizationId: organizationId, tokenExpirationDate: tokenExpirationDate || undefined, isClient: isClient })
                .then((response) => {
                    if (response.isSuccess()) {
                        setToken(response.getEntity()?.token);

                        SuccessNotification({
                            message: 'api access created successfully',
                            description: ''
                        });

                    } else {
                        const msg = JSON.parse(response.getError());
                        ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                    }
                    setIsLoading(false);
                })
                .catch((info: any) => {
                    setIsLoading(false);
                    ErrorNotification({
                        message: info,
                        description: ''
                    });
                });
        }

        catch (e: any) {
            ErrorNotification({
                message: 'Form not filled correctly',
                description: 'Please, make sure that all the fields are filled correctly'
            });
            setIsLoading(false);
        }
    }

    return (
        <Card title='Create api access'>

            <Form form={form}>

                <Row gutter={[16, 16]}>

                    <Col>
                        <Form.Item name={'organizationId'}
                            label="Organization ID"
                            rules={[
                                {
                                    required: true,
                                    pattern: new RegExp(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi),
                                    message: (
                                        <span data-testid="errorOrganizationId">
                                            Please insert a valid value
                                        </span>
                                    )
                                }
                            ]}>

                            <Input
                                style={{ width: 300 }}
                                placeholder={''}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name='tokenExpirationDate' label='Token expiration date'>
                            <DatePicker />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={[16, 16]}>
                    <Col>
                        <Form.Item name='isClient' label='Create client token'>
                        <Checkbox 
                            checked={isClient}
                            onChange={handleCheckboxChange}
                            />
                        </Form.Item>
                    </Col>
                </Row>            
                {token === '' ? null : <Row>
                    <label>ApiAccess created with token : {token}</label>
                </Row>}

                <Divider />

                <Row>

                    <Button type='primary' onClick={() => createApiAccess()} disabled={isLoading}>
                        Create API acccess
                    </Button>

                </Row>
            </Form>
        </Card>

    );
};

export default CreateApiAccess;

