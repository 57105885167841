import { Button, Card, Space } from 'antd';
import { Link } from 'react-router-dom';

const CompanyTabsSelection = (): JSX.Element => {
  return (
    <Card title='Operate on Company or power bi api'>
      <Space direction='horizontal'>
        <Link to={`/company/operations`}>
          <Button>Company</Button>
        </Link>

        <Link to={`/company/powerbi-api`}>
          <Button>PowerBi API</Button>
        </Link>
      </Space>
    </Card>
  );
};

export default CompanyTabsSelection;
