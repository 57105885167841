import { Button, Card, Col, Divider, Form, Input, Row, Space } from 'antd';
import { useContext } from 'react';
import ErrorNotification from 'src/components/Notification/ErrorNotification';
import SuccessNotification from 'src/components/Notification/SuccessNotification';
import { ToolAdminContext } from 'src/providers/AdminToolProvider';

const ResyncConcreteLoadByBcqdOrderId = (): JSX.Element => {

    const { operationalToolClient } = useContext(ToolAdminContext);

    const [form] = Form.useForm();


    async function ResyncConcreteLoadByBcqdOrderId() {

        try {
            const {
                companyId,
                projectId,
                bcqdOrderId
            } = await form.validateFields();


            operationalToolClient.resyncBySupplierConcreteLoadsByBcqdOrderId(companyId, projectId, bcqdOrderId)
            .then((response) => {
                if (response.isSuccess()) {
                    
                    SuccessNotification({
                        message: "concrete loads updated correctly",
                        description: ''
                    });


                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message , description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
        }
        
        catch (e: any) {
            ErrorNotification({
                message: 'Form not filled correctly',
                description: 'Please, make sure that all the fields are filled correctly'
            });
        }

    }


    return (
            <Card title='Resync Concrete load by bcqd order id'>

                <Form form={form}>

                    <Row gutter={[16, 16]}>
                    <Col>
                            <Form.Item name={'companyId'} 
                                label="Company id"
                                rules={[
                                    {
                                        required: true,
                                        message: (
                                            <span data-testid="errorId">
                                                This field is required
                                            </span>
                                        )
                                    }
                                ]}
                            >
                                <Input
                                    style={{ width: 300 }} 
                                    placeholder={''}
                                />
                            </Form.Item>

                        </Col>
                        <Col>
                            <Form.Item name={'projectId'} 
                                label="Project id"
                                rules={[
                                    {
                                        required: true,
                                        message: (
                                            <span data-testid="errorId">
                                                This field is required
                                            </span>
                                        )
                                    }
                                ]}
                            >
                                <Input
                                    style={{ width: 300 }} 
                                    placeholder={''}
                                />
                            </Form.Item>

                        </Col>
                        <Col>
                            <Form.Item name={'bcqdOrderId'} 
                                label="BCQD order id"
                                rules={[
                                    {
                                        required: true,
                                        message: (
                                            <span data-testid="errorId">
                                                This field is required
                                            </span>
                                        )
                                    }
                                ]}
                            >
                                <Input
                                    style={{ width: 300 }} 
                                    placeholder={''}
                                />
                            </Form.Item>

                        </Col>
                    </Row>

                    <Divider />

                    <Row>
                        <Space direction='horizontal'>
                            <Button type='primary' onClick={() => ResyncConcreteLoadByBcqdOrderId()}>
                                Resync concrete loads by BCQD order ID
                            </Button>
                        </Space>
                    </Row>
                    
                </Form>
            </Card>
            
    );
};

export default ResyncConcreteLoadByBcqdOrderId;

