import { Space } from 'antd';
import AddCompanyRoles from './components/AddCompanyRole';
import CompanyUserTable from './components/CompanyUserTable';
import CreateCompany from './components/CreateCompany';
import CreateCompanyMember from './components/CreateCompanyMember';
import CreateOrganization from './components/CreateOrganization';
import UpdateCompany from './components/UpdateCompanyWithOrganization';
import CompanyTopBar from 'src/components/TopBar/CompanyTopBar';

const CompanyManagement = (): JSX.Element => {

    return (
        <CompanyTopBar>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>

                <CreateOrganization></CreateOrganization>
                <CreateCompany></CreateCompany>
                <UpdateCompany></UpdateCompany>
                <CreateCompanyMember></CreateCompanyMember>
                <AddCompanyRoles></AddCompanyRoles>
                <CompanyUserTable></CompanyUserTable>
            </Space>
        </CompanyTopBar>
    );
};

export default CompanyManagement;


