import { Button, Divider, Space } from 'antd';
import { Link } from 'react-router-dom';

import { FunctionComponent } from 'react';

const CompanyTopBar: FunctionComponent<{}> = (props) => {
  return (
    <div>
      <Space direction='horizontal'>
        <Link to={`/company/operations`}>
          <Button>Company</Button>
        </Link>

        <Link to={`/company/powerbi-api`}>
          <Button>PowerBi API</Button>
        </Link>
        <Divider />
      </Space>
      {props.children}
    </div>
  );
};

export default CompanyTopBar;