import { OperationalToolOrderDataTable } from '@nexploretechnology/concreting-core-client';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';

export const projectOrdersColumns: () => ColumnsType<OperationalToolOrderDataTable>  = () => {

    return [

        {
            title: 'Concrete supplier order id',
            dataIndex: 'concreteSupplierOrderId',
            key: 'concreteSupplierOrderId',
        },

        {
            title: 'Order number',
            dataIndex: 'id',
            key: 'id',
        },

        {
            title: 'Delivery Time',
            dataIndex: 'deliveryTime',
            key: 'deliveryTime',
            render: (deliveryTime: Date) => <>{`${moment(deliveryTime).format('DD/MM/YYYY HH:mm').toString()}`}</>
        },


        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },

    ];
};

