import { useContext, useEffect, useState } from 'react';
import Topbar from '../components/TopBar';

import { Col, Form, message, Divider, Card, DatePicker, Input, Button, Row } from 'antd';

import SimmaOrdersTable from '../components/SimmaOrdersTable';

import { SimmaOrdersTableRecord } from '../models/SimmaOrdersTableRecord';
import { Modal } from 'antd';
import { Config, ConfigContext } from '../providers/ConfigProvider';

import {
    getOrders,
    getOrder,
    updateOrder,
} from '../services/mbk-simma-mock/simma/OrderServices/OrderServices';
import {
    ServiceSimmaRequestOrder,
    SimmaOrderStatus,
} from '../services/mbk-simma-mock/simma/OrderServices/models/ServiceSimmaRequestOrder';

import moment from 'moment';

let savedProjectId: string = '';
let savedFromDate: Date;
let savedToDate: Date;

const SimmaOrders = (): JSX.Element => {
    const config: Config = useContext(ConfigContext);
    const apiPath: string = `${config.mbkHost}${config.mbkHostApi}`;

    const [projectId, setProjectId] = useState<string>(savedProjectId);
    const [fromDate, setFromDate] = useState<Date>(savedFromDate);
    const [toDate, setToDate] = useState<Date>(savedToDate);

    const [tableRecords, setTableRecords] = useState<SimmaOrdersTableRecord[]>(
        []
    );

    async function loadOrder(orderId: string): Promise<ServiceSimmaRequestOrder> {
        return await getOrder(apiPath, orderId);
    }

    useEffect(() => {
        (async () => {
            if (projectId) {
                refreshOrdersForm(mapOrders(await loadOrders()));
            }
        })();
    }, [projectId, fromDate, toDate]);


    async function loadOrders(): Promise<ServiceSimmaRequestOrder[]> {
        return await getOrders(
            apiPath,
            projectId,
            fromDate || new Date('2000-01-01'),
            toDate || new Date('3000-01-01')
        );
    }

    function mapOrders(
        orders: ServiceSimmaRequestOrder[]
    ): SimmaOrdersTableRecord[] {
        const newTableRecords: SimmaOrdersTableRecord[] = [];

        for (const order of orders) {
            newTableRecords.push({
                key: order.id,
                orderId: order.id,

                deliveryTime: order.startdate,
                orderNumber: order.orderbarcode,
                supplierOrderid: order.orderid,
                concreteType: order.orderint.orderprod[0].productno,
                quantity: `${order.orderint.orderprod[0].specqty} ㎥`,
                performance: `${order.orderint.orderprod[0].dischqty} ㎥/h`,
                status: order.status,
                cancelled: order.cancelled,
            });
        }

        return newTableRecords;
    }

    function refreshOrdersForm(
        simmaOrdersTableRecord: SimmaOrdersTableRecord[]
    ): void {
        setTableRecords(simmaOrdersTableRecord);
    }

    async function loadAndRefresh(): Promise<void> {
        try {
            refreshOrdersForm(mapOrders(await loadOrders()));

            message.success('Data loaded');
        } catch (error) {
            message.error(`Data loading failed with error: ${error}`);
        }
    }

    const cancelSimmaOrder = (orderId: string, orderNumber: string) => {
        Modal.confirm({
            title: `Cancel order ${orderNumber}`,
            content: `Do you really want to cancel the order? ${orderNumber}`,
            okText: 'Confirm',
            async onOk(): Promise<void> {
                try {
                    const simmaRequestOrder: ServiceSimmaRequestOrder = await loadOrder(
                        orderId
                    );
                    simmaRequestOrder.status = SimmaOrderStatus.CONFIRMED;
                    simmaRequestOrder.cancelled = '1';

                    await updateOrder(apiPath, orderId, simmaRequestOrder);

                    message.success('Cancel order succeeded');

                    loadAndRefresh();
                } catch (error) {
                    message.error(`Cancel order failed with error: ${error}`);
                }
            },
            onCancel(): any { },
        });
    };

    function ProjectId_onChange(newProjectId: string): void {
        setProjectId(newProjectId);
        savedProjectId = newProjectId;
    }

    function FromDate_onChange(date: Date): void {
        setFromDate(date);
        savedFromDate = date;
    }

    function ToDate_onChange(date: Date): void {
        setToDate(date);
        savedToDate = date;
    }

    function GetOrders_onClick(): void {
        loadAndRefresh();
    }

    const [form] = Form.useForm();

    return (
        <Topbar>
            <Card title='SIMMA orders'>

                <Form
                    form={form}
                    initialValues={
                        {
                            projectId: projectId,
                            ...(fromDate && { fromDate: moment(fromDate) }),
                            ...(toDate && { toDate: moment(toDate) }),
                        }
                    }>

                    <Row gutter={[16, 16]}>

                        <Col>

                            <Form.Item name={'projectId'} >
                                <Input
                                    addonBefore='SIMMA PROJECT ID'
                                    onChange={(e) => ProjectId_onChange(e.target.value)}
                                    value={projectId}
                                />
                            </Form.Item>

                        </Col>

                        <Col>
                            FROM
                        </Col>

                        <Col>
                            <Form.Item name={'fromDate'} >
                                <DatePicker
                                    onChange={(date: any, dateString: string) =>
                                        FromDate_onChange(date?.toDate())
                                    }
                                    format='DD.MM.YYYY'
                                    value={fromDate ? moment(fromDate) : undefined}
                                />
                            </Form.Item>
                        </Col>

                        <Col>
                            TO
                        </Col>

                        <Col>
                            <Form.Item name={'toDate'} >
                                <DatePicker
                                    onChange={(date: any, dateString: string) =>
                                        ToDate_onChange(date?.toDate())
                                    }
                                    format='DD.MM.YYYY'
                                    value={toDate ? moment(toDate) : undefined}
                                />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Divider />

                    <Row>

                        <Button type='primary' onClick={() => GetOrders_onClick()}>
                            Get orders
                        </Button>

                        <Divider />

                    </Row>

                    <Row>

                        <SimmaOrdersTable
                            dataSource={tableRecords}
                            cancelSimmaOrder={cancelSimmaOrder}
                        />

                    </Row>
                </Form>
            </Card>
        </Topbar>
    );
};

export default SimmaOrders;
