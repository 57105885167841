import ResyncConcreteLoadByBcqdOrderId from "src/busines-pages/orders/components/ResyncConcreteLoadByBcqdOrderId";
import ResyncConcreteLoadById from "src/busines-pages/orders/components/ResyncConcreteLoadById";
import ResyncConcreteLoadByProjectId from "src/busines-pages/orders/components/ResyncConcreteLoadByProjectId";
import TopBar from "../TopBar";
import CreateSubscription from "./CreateSubscription";
import DeleteSubscription from "./DeleteSubscription";
import GetDeliveryNotes from "./GetDeliveryNotes";
import GetSubscriptions from "./GetSubscriptions";
import SyncDeliveryNotes from "./SyncDeliveryNotes";

const DsaPage = (): JSX.Element => {
  return (
    <TopBar>
      <CreateSubscription />
      <DeleteSubscription />
      <GetSubscriptions />
      <GetDeliveryNotes />
      <SyncDeliveryNotes />
      <ResyncConcreteLoadById />
      <ResyncConcreteLoadByBcqdOrderId />
      <ResyncConcreteLoadByProjectId />
    </TopBar>
  );
};

export default DsaPage;
