import { Direction, OffsetPagination, OperationalToolBasicConcreteLoadData } from '@nexploretechnology/concreting-core-client';
import { Button, Card, Col, Form, Input, Row, Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { useContext, useState } from 'react';
import ErrorNotification from 'src/components/Notification/ErrorNotification';
import { ToolAdminContext } from 'src/providers/AdminToolProvider';
import { orderConcreteLoadsColumns } from './OrderConcreteLoadsTable.columns';


const OrderConcreteLoadsTable = () => {
    const [companyId, setCompanyId] = useState();
    const [projectId, setProjectId] = useState();
    const [orderId, setOrderId] = useState();
    const [concreteLoads, setConcreteLoads] = useState([] as OperationalToolBasicConcreteLoadData[]);
    const [totalConcreteLoads, setTotalConcreteLoads] = useState<number>(0);
    const [tableLoading, setTableLoading] = useState<boolean>(false);

    const pageSize: number = 10;

    const pagination = {
        position: ['bottomRight'],
        pageSize: pageSize,
        total: totalConcreteLoads
    } as TablePaginationConfig;
    let sorting: Partial<Record<keyof OperationalToolBasicConcreteLoadData, Direction>>;

    const { operationalToolClient } = useContext(ToolAdminContext);
    
    const [form] = Form.useForm();

    async function onChange(
        paginationOnChange: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<OperationalToolBasicConcreteLoadData> | SorterResult<OperationalToolBasicConcreteLoadData>[],
        extra: TableCurrentDataSource<OperationalToolBasicConcreteLoadData>
    ) {
        console.log(sorter);
        sorter = sorter as SorterResult<OperationalToolBasicConcreteLoadData>;

        if (sorter.columnKey && sorter.order) {
            const sortDirection: string = { ascend: 'asc', descend: 'desc' }[sorter.order];
            const sortingValue: string = `{"${sorter.columnKey}": "${sortDirection || 'DESC'}"}`;
            sorting = JSON.parse(sortingValue);
        }

        const queryParams: OffsetPagination<OperationalToolBasicConcreteLoadData> = {
            skip:
                paginationOnChange.current! === 0
                    ? 0
                    : (paginationOnChange.current! - 1) * paginationOnChange.pageSize!, 
            limit: paginationOnChange?.pageSize!,
            sorting: sorting ? [sorting] : []
        };

        loadOrderConcreteLoads(companyId, projectId, orderId, queryParams);
    }

    async function validateForm() {

        try {
            const {
                companyId,
                projectId,
                orderId,
            } = await form.validateFields();
            
            setCompanyId(companyId);
            setProjectId(projectId);
            setOrderId(orderId);
            loadOrderConcreteLoads(companyId, projectId, orderId);
        }
        
        catch (e: any) {
            ErrorNotification({
                message: 'Form not filled correctly',
                description: 'Please, make sure that all the fields are filled correctly'
            });
        }

    }

    const loadOrderConcreteLoads = async (companyId: string, projectId: string, orderId: string, queryParams?: OffsetPagination<OperationalToolBasicConcreteLoadData>) => {
        
        const paginationParam: OffsetPagination<OperationalToolBasicConcreteLoadData> = {
            skip: queryParams ? queryParams.skip : 0,
            limit: queryParams ? queryParams.limit : pageSize,
            sorting: queryParams && queryParams.sorting ? queryParams.sorting : []
        };

        setTableLoading(true);
        operationalToolClient
            .loadConcreteLoadsByOrder(companyId, projectId, orderId, paginationParam)
            .then((response) => {
                if (response.isSuccess()) {
                    setConcreteLoads(response.getEntity().data);
                    setTotalConcreteLoads(response.getEntity().count);
                    setTableLoading(false);
                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message , description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
    };


    return  (

        <Card title='Get concrete loads by order id'>

            <Form form={form}>

                <Row gutter={[16, 16]}>

                    <Col>
                        <Form.Item name={'companyId'} 
                            label="Company Id"
                            rules={[
                                {
                                    required: true,
                                    pattern: new RegExp(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi),
                                    message: (
                                        <span data-testid="errorCompanyId">
                                            Please insert a valid value
                                        </span>
                                    )
                                }
                            ]}>

                            <Input
                                style={{ width: 300 }} 
                                placeholder={''}
                            />
                        </Form.Item>
                    </Col>

                    <Col>
                        <Form.Item name={'projectId'} 
                            label="Project Id"
                            rules={[
                                {
                                    required: true,
                                    pattern: new RegExp(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi),
                                    message: (
                                        <span data-testid="errorProjectId">
                                            Please insert a valid value
                                        </span>
                                    )
                                }
                            ]}>

                            <Input
                                style={{ width: 300 }} 
                                placeholder={''}
                            />
                        </Form.Item>
                    </Col>

                    <Col>
                        <Form.Item name={'orderId'} 
                            label="Order Id"
                            rules={[
                                {
                                    required: true,
                                    pattern: new RegExp(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi),
                                    message: (
                                        <span data-testid="errorOrderId">
                                            Please insert a valid value
                                        </span>
                                    )
                                }
                            ]}>

                            <Input
                                style={{ width: 300 }} 
                                placeholder={''}
                            />
                        </Form.Item>
                    </Col>

                </Row>

                <Row>

                <Button type='primary' onClick={() => validateForm()}>
                    Get concrete loads
                </Button>

                </Row>

            </Form>

            {!companyId || !projectId || !orderId ? null : 
                <Table
                    style={{marginTop: '1.5rem'}}
                    data-testid="order-concrete-loads-table"
                    rowClassName="orderConcreteLoadRow"
                    rowKey={'id'}
                    columns={orderConcreteLoadsColumns()}
                    dataSource={concreteLoads}
                    loading={tableLoading}
                    onChange={onChange}
                    pagination={pagination}
                />
            }
        </Card>
    );
};

export default OrderConcreteLoadsTable;
