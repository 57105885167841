import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Space, Button } from 'antd';
import { Link } from 'react-router-dom';

import { FunctionComponent } from 'react';

import { SauterDeliveryNotesTableRecord } from '../../models/SauterDeliveryNotesTableRecord';

const SauterDeliveryNotesTable: FunctionComponent<{
  dataSource: SauterDeliveryNotesTableRecord[];
}> = (props) => {
  const records: SauterDeliveryNotesTableRecord[] = props.dataSource;

  const columns: ColumnsType<SauterDeliveryNotesTableRecord> = [
    {
      title: 'License plate',
      dataIndex: 'licensePlate',
      key: 'License plate',
      render: (value: any, record: SauterDeliveryNotesTableRecord) => (
        <Space direction='vertical'>{record.licensePlate?.toUpperCase()}</Space>
      ),
    },

    {
      title: 'Delivery note no',
      dataIndex: 'deliveryNoteNumber',
      key: 'Delivery note no',
      render: (value: any, record: SauterDeliveryNotesTableRecord) => (
        <Space direction='vertical'>
          {record.deliveryNoteNumber?.toUpperCase()}
        </Space>
      ),
    },

    {
      title: 'Loading time',
      dataIndex: 'loadingTime',
      key: 'Loading time',
      render: (value: any, record: SauterDeliveryNotesTableRecord) => (
        <Space direction='vertical'>{record.loadingTime}</Space>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'Quantity',
    },
    {
      title: 'Delivery note',
      dataIndex: 'Delivery note',
      key: 'Delivery note',
      render: (value: any, record: SauterDeliveryNotesTableRecord) => (
        <Space size='middle'>
          <Link
            to={`/Sauter/order/${record.orderId}/deliverynote/${record.deliveryNoteId}`}
          >
            <Button type='link'>Edit</Button>
          </Link>
        </Space>
      ),
    },
  ];

  if (!records.length) {
    return <h1 data-testid='no-table' aria-hidden='true'></h1>;
  }

  return (
    <div data-testid='table'>
      <Table columns={columns} dataSource={records} />
    </div>
  );
};

export default SauterDeliveryNotesTable;
