import { Space } from 'antd';
import OrderConcreteLoadsTable from './components/OrderConcreteLoadsTable';
import UpdateConcreteLoadOrderId from './components/UpdateConcreteLoadOrderId';

const CoreManagement = (): JSX.Element => {

    return (

        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <OrderConcreteLoadsTable></OrderConcreteLoadsTable>
            <UpdateConcreteLoadOrderId></UpdateConcreteLoadOrderId>
        </Space>
    );
};

export default CoreManagement;


