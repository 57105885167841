import { getAxiosClient } from '../../../../auth/authService';

import {
  ServiceDeliveryNote,
  ServiceSauterResponseInfo,
} from './models/ServiceDeliveryNote';

export const getDeliveryNotes = async (
  apiPath: string,
  orderId: string,
  minDate: Date,
  maxDate: Date
): Promise<ServiceDeliveryNote[]> => {
  async function fetchJson(): Promise<ServiceSauterResponseInfo> {
    const path: string = `/spenner-sauter/?orderId=${orderId}&min_date=${minDate}&max_date=${maxDate}`;
    const endpoint: string = `${apiPath}${path}`;

    return await getAxiosClient()
      .get(endpoint)
      .then((res) => res.data);
  }

  function deserialize(
    serviceSauterResponseInfo: ServiceSauterResponseInfo
  ): ServiceSauterResponseInfo {
    serviceSauterResponseInfo.Details = serviceSauterResponseInfo.Details.map(
      (deliveryNote: ServiceDeliveryNote) => {
        deliveryNote.createdOn = new Date(deliveryNote.createdOn);
        deliveryNote.lastModified = new Date(deliveryNote.lastModified);
        deliveryNote.dateFromDatumForMongoDbFiltering = new Date(
          deliveryNote.dateFromDatumForMongoDbFiltering
        );

        return deliveryNote;
      }
    );

    return serviceSauterResponseInfo;
  }

  function map(
    serviceSauterResponseInfo: ServiceSauterResponseInfo
  ): ServiceDeliveryNote[] {
    return serviceSauterResponseInfo.Details;
  }

  return map(deserialize(await fetchJson()));
};

export const getDeliveryNote = async (
  apiPath: string,
  deliveryNoteId: string
): Promise<ServiceDeliveryNote> => {
  async function fetchJson(): Promise<ServiceDeliveryNote> {
    const path: string = `/spenner-sauter/deliverynote/${deliveryNoteId}/`;
    const endpoint: string = `${apiPath}${path}`;

    return await getAxiosClient()
      .get(endpoint)
      .then((res) => res.data);
  }

  function deserialize(deliveryNote: ServiceDeliveryNote): ServiceDeliveryNote {
    deliveryNote.createdOn = new Date(deliveryNote.createdOn);
    deliveryNote.lastModified = new Date(deliveryNote.lastModified);
    deliveryNote.dateFromDatumForMongoDbFiltering = new Date(
      deliveryNote.dateFromDatumForMongoDbFiltering
    );

    return deliveryNote;
  }

  return deserialize(await fetchJson());
};

export const createDefaultDeliveryNotes = async (
  apiPath: string,
  orderId: string
): Promise<void> => {
  const path: string = `/spenner-sauter/order/${orderId}/createDefault`;
  const endpoint: string = `${apiPath}${path}`;

  return await getAxiosClient()
    .get(endpoint)
    .then((res) => res.data);
};

export const createCustomDeliveryNotes = async (
  apiPath: string,
  orderId: string,
  deliveryNotes: ServiceDeliveryNote[]
): Promise<void> => {
  const path: string = `/spenner-sauter/order/${orderId}/create`;
  const endpoint: string = `${apiPath}${path}`;

  return await getAxiosClient()
    .post(endpoint, deliveryNotes)
    .then((res) => res.data);
};

export const updateDeliveryNote = async (
  apiPath: string,
  deliveryNoteId: string,
  deliveryNote: ServiceDeliveryNote
): Promise<void[]> => {
  const path: string = `/spenner-sauter/deliverynote/${deliveryNoteId}`;
  const endpoint: string = `${apiPath}${path}`;

  // we need to remove the following, otherwise we get "Found $id field without a $ref before it, which is invalid." from mongodb
  delete (deliveryNote as any).$id;

  return await getAxiosClient()
    .put(endpoint, deliveryNote)
    .then((res) => res.data);
};
