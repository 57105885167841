import { OperationalToolClient } from '@nexploretechnology/concreting-core-client';

import * as React from 'react';
import {  getAxiosInstanceCore } from './IdentityProvider';
interface ToolAdminProps {
    operationalToolClient: OperationalToolClient;
}

type ProviderChildren = { children: React.ReactNode };

const ToolAdminContext = React.createContext({} as ToolAdminProps);

const ToolAdminProvider = ({ children }: ProviderChildren) => {

    const value: ToolAdminProps = {
        operationalToolClient: new OperationalToolClient(getAxiosInstanceCore()),
    };
    return <ToolAdminContext.Provider value={value}>{children}</ToolAdminContext.Provider>;
};

export { ToolAdminProvider, ToolAdminContext };
