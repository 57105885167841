import { Button, Card, Col, Divider, Form, Input, Row } from 'antd';
import { useContext, useState } from 'react';
import ErrorNotification from 'src/components/Notification/ErrorNotification';
import SuccessNotification from 'src/components/Notification/SuccessNotification';
import { ToolAdminContext } from 'src/providers/AdminToolProvider';

const CreateCompany = (): JSX.Element => {

    const [companyId, setCompanyId] = useState('');
    const { operationalToolClient } = useContext(ToolAdminContext);

    const [form] = Form.useForm();


    async function createCompany() {

        try {
            const {
                companyName,
                organizationId
            } = await form.validateFields();

            operationalToolClient.createCompany({name: companyName, organizationId: organizationId})
            .then((response) => {
                if (response.isSuccess()) {

                    setCompanyId(response.getEntity().id);

                    SuccessNotification({
                        message: 'company created successfully',
                        description: ''
                    });

                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message , description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
        }
        
        catch (e: any) {
            ErrorNotification({
                message: 'Form not filled correctly',
                description: 'Please, make sure that all the fields are filled correctly'
            });
        }

    }

    return (
            <Card title='Create company'>

                <Form form={form}>

                    <Row gutter={[16, 16]}>

                        <Col>
                            <Form.Item name={'companyName'} 
                                label="Company Name"
                                rules={[
                                    {
                                        required: true,
                                        pattern: new RegExp(/^[^\s]+(\s+[^\s]+)*$/),
                                        message: (
                                            <span data-testid="errorCompanyName">
                                                Please insert a valid value
                                            </span>
                                        )
                                    }
                                ]}>

                                <Input
                                    style={{ width: 300 }} 
                                    placeholder={''}
                                />
                            </Form.Item>

                        </Col>

                        <Col>
                            <Form.Item name={'organizationId'} 
                                label="Organization ID"
                                rules={[
                                    {
                                        required: false,
                                        pattern: new RegExp(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi),
                                        message: (
                                            <span data-testid="errorOrganizationId">
                                                Please insert a valid value
                                            </span>
                                        )
                                    }
                                ]}>

                                <Input
                                    style={{ width: 300 }} 
                                    placeholder={''}
                                />
                            </Form.Item>

                        </Col>

                    </Row>

                    { companyId === '' ? null : <Row>
                        <label>Company created with id : {companyId}</label>
                    </Row>}

                    <Divider />

                    <Row>

                        <Button type='primary' onClick={() => createCompany()}>
                            Create company
                        </Button>

                    </Row>
                </Form>
            </Card>
            
    );
};

export default CreateCompany;

