import { getAxiosClient } from '../../../../auth/authService';

import { ServiceSimmaRequestOrder } from './models/ServiceSimmaRequestOrder';

export const getOrders = async (
  apiPath: string,
  projectId?: string,
  fromDate?: Date,
  toDate?: Date
): Promise<ServiceSimmaRequestOrder[]> => {
  async function fetchJson(): Promise<ServiceSimmaRequestOrder[]> {
    const queries: string[] = [];
    if (projectId) {
      queries.push(`projid=${projectId}`);
    }
    if (fromDate) {
      queries.push(`fromDate=${fromDate.toISOString()}`);
    }
    if (toDate) {
      queries.push(`toDate=${toDate.toISOString()}`);
    }

    let query: string = '';
    for (const entry of queries) {
      if (query.length === 0) {
        query += '?' + entry;
      } else {
        query += '&' + entry;
      }
    }

    const path: string = `/mbk-simma/getOrder/${query}`;
    const endpoint: string = `${apiPath}${path}`;

    return await getAxiosClient()
      .get(endpoint)
      .then((res) => {
        return res.data;
      });
  }

  function deserialize(
    orders: ServiceSimmaRequestOrder[]
  ): ServiceSimmaRequestOrder[] {
    return orders.map((order: ServiceSimmaRequestOrder) => {
      order.startdate = new Date(order.startdate);
      return order;
    });
  }

  return deserialize(await fetchJson());
};

export const getOrder = async (
  apiPath: string,
  orderId: string
): Promise<ServiceSimmaRequestOrder> => {
  async function fetchJson(): Promise<ServiceSimmaRequestOrder[]> {
    const queries: string[] = [];
    if (orderId) {
      queries.push(`orderid=${orderId}`);
    }

    let query: string = '';
    for (const entry of queries) {
      if (query.length === 0) {
        query += '?' + entry;
      } else {
        query += '&' + entry;
      }
    }

    const path: string = `/mbk-simma/getOrder/${query}`;
    const endpoint: string = `${apiPath}${path}`;

    return await getAxiosClient()
      .get(endpoint)
      .then((res) => {
        return res.data;
      });
  }

  function deserialize(
    orders: ServiceSimmaRequestOrder[]
  ): ServiceSimmaRequestOrder[] {
    return orders.map((order: ServiceSimmaRequestOrder) => {
      order.startdate = new Date(order.startdate);

      if (order.activeVersion) {
        order.activeVersion.startdate = new Date(order.activeVersion.startdate);
      }
      return order;
    });
  }

  return deserialize(await fetchJson())[0];
};

export const confirmOrder = async (
  apiPath: string,
  orderId: string
): Promise<void[]> => {
  const path: string = `/mbk-simma/order/${orderId}?status=0`;
  const endpoint: string = `${apiPath}${path}`;

  return await getAxiosClient()
    .put(endpoint)
    .then((res) => res.data);
};

export const rejectOrder = async (
  apiPath: string,
  orderId: string
): Promise<void[]> => {
  const path: string = `/mbk-simma/order/${orderId}?status=5`;
  const endpoint: string = `${apiPath}${path}`;

  return await getAxiosClient()
    .put(endpoint)
    .then((res) => res.data);
};

export const cancelOrder = async (
  apiPath: string,
  orderId: string
): Promise<void[]> => {
  const path: string = `/mbk-simma/order/${orderId}?status=0`;
  const endpoint: string = `${apiPath}${path}`;

  return await getAxiosClient()
    .put(endpoint)
    .then((res) => res.data);
};

export const updateOrder = async (
  apiPath: string,
  orderId: string,
  simmaRequestOrder: ServiceSimmaRequestOrder
): Promise<void[]> => {
  const path: string = `/mbk-simma/updateOrder/${orderId}`;
  const endpoint: string = `${apiPath}${path}`;

  // we need to remove the following, otherwise we get "Found $id field without a $ref before it, which is invalid." from mongodb
  delete (simmaRequestOrder as any).$id;

  return await getAxiosClient()
    .put(endpoint, simmaRequestOrder)
    .then((res) => res.data);
};
