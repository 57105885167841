import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import Keycloak from 'keycloak-js';
import React, { useContext, useEffect, useState } from 'react';
import { Locale } from '../types';
import { ConfigContext } from './ConfigProvider';

export interface Identity {
  profile: Keycloak.KeycloakProfile;
  keycloak: Keycloak;
  locale: Locale;
  token: string;
}

type LocaleOptions = typeof Locale[keyof typeof Locale];

export const IdentityContext = React.createContext<Identity>({
  profile: {},
  keycloak: {} as Keycloak,
  locale: Locale.en,
  token: '',
});

let keycloak: any;

let axiosInstance: AxiosInstance;
let axiosInstanceCore: AxiosInstance;

const IdentityProvider: React.FunctionComponent = (props) => {
  const [profile, setProfile] = useState<Keycloak.KeycloakProfile>();
  const [instance, setInstance] = useState<Keycloak.KeycloakInstance>();
  const [locale, setLocale] = useState<Locale>(Locale.en);
  const config = useContext(ConfigContext);

  useEffect(() => {

    const init = async () => {
      keycloak = new Keycloak({
        url: config.keycloakConfig.url,
        realm: config.keycloakConfig.realm,
        clientId: config.keycloakConfig.clientId,
      });
      try {
        const success = await keycloak.init({
          onLoad: 'login-required',
             checkLoginIframe: false
        }
        );
        if (success) {
          const loadedProfile = await keycloak.loadUserProfile();

          const axiosConfig: AxiosRequestConfig = {
            baseURL: config.host + '/api/v1',
            validateStatus: (status) => status < 600
          }

          const axiosConfigCore: AxiosRequestConfig = {
            baseURL: config.coreHost + '/api/v1',
            validateStatus: (status) => status < 600
          }
          
          axiosInstance = axios.create(axiosConfig);
          axiosInstanceCore = axios.create(axiosConfigCore);
          axiosInstance.interceptors.request.use(async (axiosInterceptorConfig) => {
            await updateToken();
            const token = getToken();
            axiosInterceptorConfig.headers['Authorization'] = 'Bearer ' + token;
            return axiosInterceptorConfig;
          });
          axiosInstanceCore.interceptors.request.use(async (axiosInterceptorConfig) => {
            await updateToken();
            const token = getToken();
            axiosInterceptorConfig.headers['Authorization'] = 'Bearer ' + token;
            return axiosInterceptorConfig;
          });
          // Unfortunately keycloaks loadUserInfo is currently undocumented, therefore we have specify the type, for at least the properties we're interested in.
          const userInfo = (await keycloak.loadUserInfo()) as {
            locale: string;
          };
          if (userInfo && userInfo.locale && userInfo.locale in Locale) {
            setLocale(Locale[userInfo.locale as LocaleOptions]);
          }

          if (keycloak.hasResourceRole('BCQD.tool.admin')) {
            setProfile(loadedProfile);
            setInstance(keycloak);
          } else {
            keycloak.logout();
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (config.keycloakConfig.url && config.keycloakConfig.realm && config.keycloakConfig.clientId) {
      init();
    }
  }, [config.keycloakConfig.url, config.keycloakConfig.realm, config.keycloakConfig.clientId]);

  // do not render anything if we do not have a valid user

  if (instance === undefined || profile === undefined) {
    return null;
  }

  // put the keycloak instance into the context and render the application
  return (
    <IdentityContext.Provider
      value={{
        profile,
        keycloak: instance,
        locale,
        token: instance.token || '',
      }}
    >
      {props.children}
    </IdentityContext.Provider>
  );
};

export default IdentityProvider;

export async function updateToken(): Promise<void> {
  try {
    await keycloak.updateToken(60);
  } catch {
    keycloak.logout();
  }
}

export function logout() {
  keycloak.logout();
}

export function getToken(): string {
  return keycloak.token as string;
}

export function getAxiosInstance(): AxiosInstance {
  return axiosInstance;
}
export function getAxiosInstanceCore(): AxiosInstance {
  return axiosInstanceCore;
}