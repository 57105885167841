import {
    ApartmentOutlined,
    BellOutlined, DeploymentUnitOutlined, LogoutOutlined, ProjectOutlined, ReconciliationOutlined, SyncOutlined, UserOutlined, ShopOutlined
} from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu, MenuProps } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ConfigContext } from 'src/providers/ConfigProvider';
import { logout } from 'src/providers/IdentityProvider';
import logoNexplore from '../../assets/images/nexplore-logo.svg';
import styles from './AppMenu.module.css';


const { Header, Sider, Content } = Layout;
type MenuChildren = { children: React.ReactNode };

const AppMenu = ({ children }: MenuChildren) => {
    const [collapsed, setCollased] = useState(true);
    const [selectedMenuItem, setSelectedMenuItem] = useState('mocks');
    const location = useLocation();
    const config = useContext(ConfigContext);


    useEffect(() => {
        onUrlChange();
    }, [location]);
    

    const onUrlChange = () => {
        if (location.pathname.includes('project')) {
            setSelectedMenuItem('project');
        }
        else if (location.pathname.includes('company')) {
            setSelectedMenuItem('company');
        }

        else if (location.pathname.includes('refresh')) {
            setSelectedMenuItem('refresh');
        }

        else if (location.pathname.includes('core')) {
            setSelectedMenuItem('core');
        }
        else if(location.pathname.includes('supplier')){
            setSelectedMenuItem('supplier');
        }
    
        else {
            setSelectedMenuItem('mocks');
        }

    };

    const onCollapse = (collapse: boolean) => {
        setCollased(collapse);
    };

    const renderTopMenu = (): JSX.Element => {
        const topMenuItems: MenuProps['items'] = [

            {
                key: 'logout',
                label: (
                    <Button data-testid="logoutMenuBtn" type="text" onClick={() => logout()}>
                        {' '}
                        <LogoutOutlined /> Logout {' '}
                    </Button>
                )
            }
        ];

        return <Menu items={topMenuItems} />;
    };

    const renderSideMenuItems = (): ItemType[] => {

        let sideMenuItems: MenuProps['items'] = [];
        
        if (config.supplierMockEnable !== 'supplier-mock-disable') {
            sideMenuItems = [
                {
                    key: 'mocks',
                    label: (
                        <Link data-testid="mocksMenuBtn" to={`/mocks`}>
                            Mocks
                        </Link>
                    ),
                    icon: <ReconciliationOutlined />
                },
            ];
        }

        sideMenuItems = sideMenuItems.concat ([
            {
                key: 'company',
                label: (
                    <Link data-testid="companyMenuBtn" to={`/company`}>
                        Company Management
                    </Link>
                ),
                icon: <ApartmentOutlined />,
            },
    
            {
                key: 'project',
                label: (
                    <Link data-testid="projectMenuBtn" to={`/project`}>
                        Project Management
                    </Link>
                ),
                icon: <ProjectOutlined />,
            },
    
            {
                key: 'core',
                label: (
                    <Link data-testid="coreMenuBtn" to={`/core`}>
                        Core Management
                    </Link>
                ),
                icon: <DeploymentUnitOutlined />,
            },
            {
                key: 'supplier',
                label: (
                    <Link data-testid="supplierMenuBtn" to={`/supplier`}>
                        Supplier Management
                    </Link>
                ),
                icon: <ShopOutlined />
            },
    
            {
                key: 'refresh',
                label: (
                    <Link data-testid="refreshMenuBtn" to={`/refresh`}>
                        Refresh orders
                    </Link>
                ),
                icon: <SyncOutlined />,
            },
        ]);
        
        return sideMenuItems;
    };


    return (
        <>
            <Layout className={styles.menu}>
                <Header className={styles.menuHeader}>
                    <div className={styles.menuTitle}>
                        <div className={styles.menuLogo}>
                            <img alt="logo" src={logoNexplore} />
                        </div>
                            <div className={styles.menuName}>
                                <Link to={ config.supplierMockEnable !== 'supplier-mock-disable' ? "/mocks" : "/company"}>
                                    Concrete
                                </Link>
                            </div>
                    </div>

                    <div className={styles.menuTitle}>

                        <Dropdown
                            trigger={['click']}
                            overlay={<></>}
                            placement="bottom"
                            className={styles.menuRightIcons}>
                            <Button
                                hidden={true}
                                icon={
                                    <div className={styles.menuDropdown}>
                                        <BellOutlined style={{ fontSize: '1.4rem' }} />
                                        <p>
                                            Updates
                                        </p>
                                    </div>
                                }></Button>
                        </Dropdown>

                        <Dropdown
                            trigger={['click']}
                            overlay={renderTopMenu}
                            placement="bottom"
                            className={styles.menuRightIcons}>
                            <Button
                                icon={
                                    <div className={styles.menuDropdown}>
                                        <UserOutlined style={{ fontSize: '1.4rem' }} />
                                        <p>
                                            Profile
                                        </p>
                                    </div>
                                }></Button>
                        </Dropdown>
                    </div>
                </Header>
                <Layout>
                    <Sider
                        className={styles.menuSidebar}
                        width={240}
                        collapsible
                        collapsed={collapsed}
                        onCollapse={onCollapse}>
                        <Menu
                            theme="dark"
                            className={styles.menuSidebar}
                            mode="inline"
                            items={renderSideMenuItems()}
                            selectedKeys={[selectedMenuItem]}
                        />
                    </Sider>
                    <Content className={styles.menuContent}>{children}</Content>
                </Layout>
            </Layout>
        </>
    );
};

export default AppMenu;
