import { useContext, useEffect, useState } from 'react';
import Topbar from '../components/TopBar';

import {
  Button, Card, DatePicker, Divider, Form, Input, message, Radio, Space,
  Tag, TimePicker
} from 'antd';

import { SimmaOrderFormRecord } from '../models/SimmaOrderFormRecord';
import {
  Config,
  ConfigContext,
  extractCurrentSimmaURL,
  formatDate,
  formatTime
} from '../providers/ConfigProvider';

import moment from 'moment';

import { ServiceSimmaRequestOrder } from '../services/mbk-simma-mock/simma/OrderServices/models/ServiceSimmaRequestOrder';
import {
  confirmOrder, getOrder, rejectOrder,
  updateOrder
} from '../services/mbk-simma-mock/simma/OrderServices/OrderServices';

function getSimmaOrderStatusColor(statusText: string): string {
  switch (statusText) {
    case 'CANCELLED':
      return 'yellow';
    case 'CONFIRMED':
      return 'green';
    case 'UPDATE_WAITING_FOR_CONFIRMATION':
      return 'blue';
    case 'REJECTED':
      return 'red';
    case 'NEW_ORDER_PENDING':
      return 'black';
    case 'INVALID':
    case 'UNKNOWN_CODE':
      return 'cyan';
  }
  return 'cyan';
}

function getSimmaOrderStatusText(
  status: string | undefined,
  cancelled: string | undefined
): string {
  if (!status) {
    return '?';
  }
  switch (status) {
    case '0': {
      if (cancelled) {
        return 'CANCELLED';
      }
      return 'CONFIRMED';
    }
    case '1':
      return 'UPDATE_WAITING_FOR_CONFIRMATION';
    case '5':
      return 'REJECTED';
    case '6':
      return 'NEW_ORDER_PENDING';
    case '-1':
      return 'INVALID';
    case '-99':
      return 'UNKNOWN_CODE';
  }
  return '?';
}

function convertFromSimmasTimezoneToUTC(
  date: Date,
  simmasTimezoneOffsetInMinutes: number
): Date {
  return new Date(
    new Date(date).getTime() + simmasTimezoneOffsetInMinutes * -60000
  );
}

function getTimezoneOffsetForTimezone(timeZone: string): number {
  type PartialDate = {
    day: number;
    hour: number;
    minute: number;
  };

  function partialDateFromString(str: string): PartialDate {
    const array: string[] = str.replace(':', ' ').split(' ');
    return {
      day: parseInt(array[0], 10),
      hour: parseInt(array[1], 10),
      minute: parseInt(array[2], 10),
    };
  }

  const now: Date = new Date();
  const other: PartialDate = partialDateFromString(
    now.toLocaleString(['en-US'], {
      timeZone,
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    })
  );
  const locale: PartialDate = partialDateFromString(
    now.toLocaleString(['en-US'], {
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    })
  );

  const otherTimezoneOffset: number =
    other.day * 24 * 60 + other.hour * 60 + other.minute;
  const localeOffset: number =
    locale.day * 24 * 60 + locale.hour * 60 + locale.minute;

  return Math.round(
    localeOffset - otherTimezoneOffset + now.getTimezoneOffset()
  ); // we need to round to get an integer value
}

const SimmaOrder = (): JSX.Element => {
  const config: Config = useContext(ConfigContext);
  const apiPath: string = `${config.mbkHost}${config.mbkHostApi}`;

  const [orderId] = extractCurrentSimmaURL();
  const simmasTimezone: string = 'Europe/Berlin'; // https://en.wikipedia.org/wiki/List_of_tz_database_time_zones

  const [formRecord, setFormRecord] = useState<SimmaOrderFormRecord>();

  const [form] = Form.useForm();

  async function loadOrder(): Promise<ServiceSimmaRequestOrder> {
    return await getOrder(apiPath, orderId);
  }

  function mapOrder(
    simmaRequestOrder: ServiceSimmaRequestOrder
  ): SimmaOrderFormRecord {
    const activeVersion: SimmaOrderFormRecord = simmaRequestOrder.activeVersion
      ? {
          orderId: simmaRequestOrder.activeVersion.id,

          deliveryTime: simmaRequestOrder.activeVersion.startdate,
          orderNumber: simmaRequestOrder.activeVersion.orderbarcode,
          supplierOrderid: simmaRequestOrder.activeVersion.orderid,
          concreteType:
            simmaRequestOrder.activeVersion.orderint.orderprod[0].productno,
          quantityAmount:
            simmaRequestOrder.activeVersion.orderint.orderprod[0].specqty,
          performanceAmount:
            simmaRequestOrder.activeVersion.orderint.orderprod[0].dischqty,
          additionalQuantity:
            simmaRequestOrder.activeVersion.orderint.orderprod[0].issurplus,
          status: simmaRequestOrder.activeVersion.status,
          cancelled: simmaRequestOrder.activeVersion.cancelled,

          activeVersion: {} as SimmaOrderFormRecord,
        }
      : ({} as SimmaOrderFormRecord);

    return {
      orderId: simmaRequestOrder.id,

      deliveryTime: simmaRequestOrder.startdate,
      orderNumber: simmaRequestOrder.orderbarcode,
      supplierOrderid: simmaRequestOrder.orderid,
      concreteType: simmaRequestOrder.orderint.orderprod[0].productno,
      quantityAmount: simmaRequestOrder.orderint.orderprod[0].specqty,
      performanceAmount: simmaRequestOrder.orderint.orderprod[0].dischqty,
      additionalQuantity: simmaRequestOrder.orderint.orderprod[0].issurplus,
      status: simmaRequestOrder.status,
      cancelled: simmaRequestOrder.cancelled,

      activeVersion,
    };
  }

  function refreshOrderForm(record: SimmaOrderFormRecord): void {
    setFormRecord(record);

    ConcreteType_onChange(record.concreteType);
    DeliveryDate_onChange(record.deliveryTime);
    DeliveryTime_onChange(record.deliveryTime);
    QuantityAmount_onChange(record.quantityAmount);
    PerformanceAmount_onChange(record.performanceAmount);
    AdditionalQuantity_onChange(record.additionalQuantity);
  }

  async function loadAndRefresh(): Promise<void> {
    try {
      refreshOrderForm(mapOrder(await loadOrder()));

      message.success('Data loaded');
    } catch (error) {
      message.error(`Data loading failed with error: ${error}`);
    }
  }

  useEffect(() => {
    loadAndRefresh();
  }, [orderId]); // eslint-disable-line react-hooks/exhaustive-deps

  async function Confirm_onClick(): Promise<void> {
    try {
      await confirmOrder(apiPath, orderId);
      message.success('Confirm order succeeded');
      loadAndRefresh();
    } catch (error) {
      message.error(`Confirm order failed with error: ${error}`);
    }
  }

  async function Reject_onClick(): Promise<void> {
    try {
      await rejectOrder(apiPath, orderId);
      message.success('Reject order succeeded');
      loadAndRefresh();
    } catch (error) {
      message.error(`Reject order failed with error: ${error}`);
    }
  }

  async function Save_onClick(): Promise<void> {
    try {
      const simmaRequestOrder: ServiceSimmaRequestOrder = await loadOrder();

      const deliveryDate: Date = form.getFieldValue('DeliveryDate').toDate();
      const deliveryTime: Date = form.getFieldValue('DeliveryTime').toDate();

      simmaRequestOrder.startdate = new Date(deliveryDate);
      simmaRequestOrder.startdate.setUTCHours(deliveryTime.getUTCHours());
      simmaRequestOrder.startdate.setUTCMinutes(deliveryTime.getUTCMinutes());

      const simmasTimezoneOffsetInMinutes: number =
        getTimezoneOffsetForTimezone(simmasTimezone);
      simmaRequestOrder.startdate = convertFromSimmasTimezoneToUTC(
        simmaRequestOrder.startdate,
        simmasTimezoneOffsetInMinutes * 2
      );

      simmaRequestOrder.orderint.orderprod[0].productno =
        form.getFieldValue('ConcreteType');
      simmaRequestOrder.orderint.orderprod[0].specqty =
        form.getFieldValue('QuantityAmount');
      simmaRequestOrder.orderint.orderprod[0].dischqty =
        form.getFieldValue('PerformanceAmount');
      simmaRequestOrder.orderint.orderprod[0].issurplus =
        form.getFieldValue('AdditionalQuantity');

      await updateOrder(apiPath, orderId, simmaRequestOrder);

      message.success('Save order succeeded');
    } catch (error) {
      message.error(`Save order failed with error: ${error}`);
    }
  }

  function ConcreteType_onChange(concreteType: string): void {
    form.setFieldsValue({ ConcreteType: concreteType });
  }

  function DeliveryDate_onChange(date: Date): void {
    form.setFieldsValue({ DeliveryDate: moment(date) });
  }

  function DeliveryTime_onChange(time: Date): void {
    form.setFieldsValue({ DeliveryTime: moment(time) });
  }

  function QuantityAmount_onChange(quantityAmount: string | number): void {
    form.setFieldsValue({ QuantityAmount: quantityAmount });
  }

  function PerformanceAmount_onChange(
    performanceAmount: string | number
  ): void {
    form.setFieldsValue({ PerformanceAmount: performanceAmount });
  }

  function AdditionalQuantity_onChange(value: boolean): void {
    form.setFieldsValue({ AdditionalQuantity: value });
  }

  return (
    <Topbar>
      <Card title='Edit SIMMA order'>
        <Space direction='vertical'>
          <Space direction='horizontal'>
            <Button type='primary' onClick={() => Confirm_onClick()}>
              Confirm
            </Button>

            <Button type='primary' onClick={() => Reject_onClick()}>
              Reject
            </Button>
          </Space>

          <Divider />

          <Space direction='vertical'>
            <Space direction='horizontal'>
              ORDER ID:
              <div>
                <b>{formRecord?.orderId}</b>
              </div>
              <Tag
                color={`${getSimmaOrderStatusColor(
                  getSimmaOrderStatusText(
                    formRecord?.status,
                    formRecord?.cancelled
                  )
                )}`}
              >
                {getSimmaOrderStatusText(
                  formRecord?.status,
                  formRecord?.cancelled
                )}
              </Tag>
              ORDER NUMBER:
              <div>
                <b>{formRecord?.orderNumber}</b>
              </div>
              CONCRETE SUPPLIER ORDER ID:
              <div>
                <b>{formRecord?.supplierOrderid}</b>
              </div>
            </Space>
          </Space>

          {formRecord?.activeVersion?.orderId ? (
            <div>
              <Divider>Current order</Divider>

              <Space direction='vertical'>
                <Space direction='horizontal'>
                  CONCRETE TYPE:
                  <div>
                    <b>{formRecord?.activeVersion.concreteType}</b>
                  </div>
                </Space>

                <Space direction='horizontal'>
                  DELIVERY DATE:
                  <div>
                    <b>{formatDate(formRecord?.activeVersion.deliveryTime)}</b>
                  </div>
                </Space>

                <Space direction='horizontal'>
                  DELIVERY TIME:
                  <div>
                    <b>{formatTime(formRecord?.activeVersion.deliveryTime)}</b>
                  </div>
                </Space>

                <Space direction='horizontal'>
                  CONCRETE QUANTITY:
                  <div>
                    <b>{formRecord?.activeVersion.quantityAmount} ㎥</b>
                  </div>
                </Space>

                <Space direction='horizontal'>
                  OUTPUT PER HOUR:
                  <div>
                    <b>{formRecord?.activeVersion.performanceAmount} ㎥/h</b>
                  </div>
                </Space>

                <Space direction='horizontal'>
                  ADDITIONAL QUANTITY:
                  <div>
                    <b>
                      {formRecord?.activeVersion.additionalQuantity
                        ? 'Yes'
                        : 'No'}
                    </b>
                  </div>
                </Space>
              </Space>

              <Divider>Update request</Divider>
            </div>
          ) : (
            <Divider />
          )}

          <Form form={form}>
            <Space direction='horizontal'>
              <Form.Item name='ConcreteType' label='CONCRETE TYPE'>
                <Input
                  onChange={(e: any) => ConcreteType_onChange(e.target.value)}
                />
              </Form.Item>

              <Form.Item name='DeliveryDate' label='DELIVERY DATE'>
                <DatePicker
                  onChange={(date: any, dateString: string) =>
                    DeliveryDate_onChange(date?.toDate())
                  }
                  format='DD.MM.YYYY'
                />
              </Form.Item>

              <Form.Item name='DeliveryTime' label='DELIVERY TIME'>
                <TimePicker
                  onChange={(time: any, dateString: string) => {
                    DeliveryTime_onChange(time?.toDate());
                  }}
                  format={'HH:mm'}
                />
              </Form.Item>
            </Space>

            <Space direction='horizontal'>
              <Form.Item name='QuantityAmount' label='CONCRETE QUANTITY'>
                <Input
                  onChange={(e: any) => QuantityAmount_onChange(e.target.value)}
                  addonAfter='㎥'
                />
              </Form.Item>

              <Form.Item name='PerformanceAmount' label='OUTPUT PER HOUR'>
                <Input
                  onChange={(e: any) =>
                    PerformanceAmount_onChange(e.target.value)
                  }
                  addonAfter='㎥/h'
                />
              </Form.Item>

              {
                <Form.Item name='AdditionalQuantity'>
                  <Radio.Group
                    onChange={(e: any) =>
                      AdditionalQuantity_onChange(e.target.value)
                    }
                  >
                    <Radio value={true}>With additional quantity</Radio>
                    <Radio value={false}>Without additional quantity</Radio>
                  </Radio.Group>
                </Form.Item>
              }
            </Space>
          </Form>

          <Space direction='horizontal'>
            <Button type='primary' onClick={() => Save_onClick()}>
              Save
            </Button>
          </Space>
        </Space>
      </Card>
    </Topbar>
  );
};

export default SimmaOrder;
