import { Button, Card, Col, Divider, Form, Input, Row, Space } from 'antd';
import { useContext } from 'react';
import ErrorNotification from 'src/components/Notification/ErrorNotification';
import SuccessNotification from 'src/components/Notification/SuccessNotification';
import { ToolAdminContext } from 'src/providers/AdminToolProvider';

const ResetConcreteLoadRetriesCounter = (): JSX.Element => {

    const { operationalToolClient } = useContext(ToolAdminContext);

    const [form] = Form.useForm();


    async function resetCounterOfConcreteLoadRetries() {

        try {
            const {
                concreteLoadId,
            } = await form.validateFields();


            operationalToolClient.resetSupplierConcreteLoadRetriesCounter(concreteLoadId)
            .then((response) => {
                if (response.isSuccess()) {
                    
                    SuccessNotification({
                        message: response.getEntity().message,
                        description: ''
                    });


                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message , description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
        }
        
        catch (e: any) {
            ErrorNotification({
                message: 'Form not filled correctly',
                description: 'Please, make sure that all the fields are filled correctly'
            });
        }

    }


    return (
            <Card title='Reset retries counter for Supplier concrete load table'>

                <Form form={form}>

                    <Row gutter={[16, 16]}>

                        <Col>
                            <Form.Item name={'concreteLoadId'} 
                                label="Concrete load id"
                                rules={[
                                    {
                                        required: true,
                                        message: (
                                            <span data-testid="errorId">
                                                This field is required
                                            </span>
                                        )
                                    }
                                ]}
                            >
                                <Input
                                    style={{ width: 300 }} 
                                    placeholder={''}
                                />
                            </Form.Item>

                        </Col>
                    </Row>

                    <Divider />

                    <Row>
                        <Space direction='horizontal'>
                            <Button type='primary' onClick={() => resetCounterOfConcreteLoadRetries()}>
                                Reset counter of retries
                            </Button>
                        </Space>
                    </Row>
                    
                </Form>
            </Card>
            
    );
};

export default ResetConcreteLoadRetriesCounter;

