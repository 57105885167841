

export enum Locale {
  de = 'de',
  en = 'en',
  es = 'es',
}


// type guard for language selector
// converting string to enum
export const isLocale = (value: string): value is Locale => value in Locale;
