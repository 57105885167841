import React, { useEffect, useState } from 'react';

export interface Config {
  keycloakConfig: {
    url: string;
    realm: string;
    clientId: string;
  };
  host: string;
  coreHost: string;
  mbkHost: string;
  mbkHostApi: string;
  supplierMockEnable: string;
}

const emptyConfig: Config = {
  keycloakConfig: {
    url: '',
    realm: '',
    clientId: '',
  },
  host: '',
  coreHost: '',
  mbkHost: '',
  mbkHostApi: '',
  supplierMockEnable: ''
};

/*
The following is the local config used when you use "npm run start" to access this service locally.
The real one gets created automatically from the chart for the namespace.
*/
const localConfig: Config = {
  keycloakConfig: {
    url: 'https://keycloak-arnaud.bcqd-dev-aks.bcqd.nexploredevops.com/auth/',
    realm: 'concrete',
    clientId: 'concrete-client',
  },
  host: "https://services-arnaud.bcqd-dev-aks.bcqd.nexploredevops.com",
  coreHost: "https://bcqd-concreting-core-arnaud.bcqd-dev-aks.bcqd.nexploredevops.com",
  mbkHost: 'https://mbk-simma-mock-arnaud.bcqd-dev-aks.bcqd.nexploredevops.com',
  mbkHostApi: '/api/v1',
  supplierMockEnable: ''
};

export const getConfig = async (
  useLocalConfigFile: boolean
): Promise<Config> => {
  if (useLocalConfigFile) {
    return localConfig;
  }

  const serverConfig: Config = await fetch('./config/config.json')
    .then((response) => {
      return response.json();
    })

    .catch((error) => {
      console.log(error);
    });

  return serverConfig;
};

export const ConfigContext = React.createContext<any>(emptyConfig);

const ConfigProvider: React.FunctionComponent = (props) => {
  const [config, setConfig] = useState<any>(emptyConfig);

  useEffect(() => {
    const useLocalConfigFile: boolean = false;

    getConfig(useLocalConfigFile)
      .then((useThisConfig: Config) => {
        if (!useThisConfig.mbkHostApi) {
          useThisConfig = { ...useThisConfig, mbkHostApi: '/api/v1' };
        }

        setConfig(useThisConfig);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <ConfigContext.Provider value={config}>
      {props.children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;

export function extractCurrentSimmaURL(): string[] {
  const location: string[] = window.location.href.split('//')[1].split('/');

  const orderId: string = location.length >= 3 ? location[3] : '';
  const deliveryNoteId: string = location.length >= 5 ? location[5] : '';

  return [orderId, deliveryNoteId];
}

export function extractCurrentSauterURL(): string[] {
  const location: string[] = window.location.href.split('//')[1].split('/');

  const orderId: string = location.length >= 3 ? location[3] : '';
  const deliveryNoteId: string = location.length >= 5 ? location[5] : '';

  return [orderId, deliveryNoteId];
}

export function formatDate(date: Date | undefined): string {
  const dateFormat = new Intl.DateTimeFormat('de-DE', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  });

  if (!date) {
    return '';
  }
  return dateFormat.format(date);
}

export function formatTime(date: Date | undefined): string {
  const timeFormat = new Intl.DateTimeFormat('de-DE', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  if (!date) {
    return '';
  }
  return timeFormat.format(date);
}
