import { getAxiosClient } from '../../../../auth/authService';

import { ServiceDeliveryNote } from './models/ServiceDeliveryNote';

export const getDeliveryNotes = async (
  apiPath: string,
  orderId: string,
  fromDate: Date,
  toDate: Date,
  skip: number,
  take: number
): Promise<ServiceDeliveryNote[]> => {
  async function fetchJson(): Promise<ServiceDeliveryNote[]> {
    const path: string = `/mbk-simma/getDelTicket/?orderId=${orderId}&fromDate=${fromDate}&toDate=${toDate}&skip=${skip}&take=${take}`;
    const endpoint: string = `${apiPath}${path}`;

    return await getAxiosClient()
      .get(endpoint)
      .then((res) => res.data);
  }

  function deserialize(
    deliveryNotes: ServiceDeliveryNote[]
  ): ServiceDeliveryNote[] {
    return deliveryNotes.map((deliveryNote: ServiceDeliveryNote) => {
      deliveryNote.dtData.loadstart = new Date(deliveryNote.dtData.loadstart);

      return deliveryNote;
    });
  }

  return deserialize(await fetchJson());
};

export const createDefaultDeliveryNotes = async (
  apiPath: string,
  orderId: string
): Promise<void> => {
  const path: string = `/mbk-simma/order/${orderId}/createDefault`;
  const endpoint: string = `${apiPath}${path}`;

  return await getAxiosClient()
    .get(endpoint)
    .then((res) => res.data);
};

export const createCustomDeliveryNotes = async (
  apiPath: string,
  orderId: string,
  deliveryNotes: ServiceDeliveryNote[]
): Promise<void> => {
  const path: string = `/mbk-simma/order/${orderId}/create`;
  const endpoint: string = `${apiPath}${path}`;

  return await getAxiosClient()
    .post(endpoint, deliveryNotes)
    .then((res) => res.data);
};

export const updateDeliveryNote = async (
  apiPath: string,
  deliveryNoteId: string,
  deliveryNote: ServiceDeliveryNote
): Promise<void[]> => {
  const path: string = `/mbk-simma/deliverynote/${deliveryNoteId}`;
  const endpoint: string = `${apiPath}${path}`;

  // we need to remove the following, otherwise we get "Found $id field without a $ref before it, which is invalid." from mongodb
  delete (deliveryNote as any).$id;

  return await getAxiosClient()
    .put(endpoint, deliveryNote)
    .then((res) => res.data);
};
