import { useContext, useEffect, useState } from 'react';
import Topbar from '../components/TopBar';

import { Button, Card, Divider, message, Space, Tag } from 'antd';
import { Link } from 'react-router-dom';

import SimmaDeliveryNotesTable from '../components/SimmaDeliveryNotesTable';

import { SimmaDeliveryNotesTableRecord } from '../models/SimmaDeliveryNotesTableRecord';

import {
  Config,
  ConfigContext,
  extractCurrentSimmaURL,
  formatDate
} from '../providers/ConfigProvider';

import {
  createDefaultDeliveryNotes, getDeliveryNotes
} from '../services/mbk-simma-mock/simma/DeliveryNoteServices/DeliveryNoteServices';
import { ServiceDeliveryNote } from '../services/mbk-simma-mock/simma/DeliveryNoteServices/models/ServiceDeliveryNote';

import { ServiceSimmaRequestOrder } from '../services/mbk-simma-mock/simma/OrderServices/models/ServiceSimmaRequestOrder';
import { getOrder } from '../services/mbk-simma-mock/simma/OrderServices/OrderServices';

import { SimmaOrderFormRecord } from '../models/SimmaOrderFormRecord';

function getSimmaOrderStatusColor(statusText: string): string {
  switch (statusText) {
    case 'CANCELLED':
      return 'yellow';
    case 'CONFIRMED':
      return 'green';
    case 'UPDATE_WAITING_FOR_CONFIRMATION':
      return 'blue';
    case 'REJECTED':
      return 'red';
    case 'NEW_ORDER_PENDING':
      return 'black';
    case 'INVALID':
    case 'UNKNOWN_CODE':
      return 'cyan';
  }
  return 'cyan';
}

function getSimmaOrderStatusText(
  status: string | undefined,
  cancelled: string | undefined
): string {
  if (!status) {
    return '?';
  }
  switch (status) {
    case '0': {
      if (cancelled) {
        return 'CANCELLED';
      }
      return 'CONFIRMED';
    }
    case '1':
      return 'UPDATE_WAITING_FOR_CONFIRMATION';
    case '5':
      return 'REJECTED';
    case '6':
      return 'NEW_ORDER_PENDING';
    case '-1':
      return 'INVALID';
    case '-99':
      return 'UNKNOWN_CODE';
  }
  return '?';
}

const SimmaDeliveryNotes = (): JSX.Element => {
  const config: Config = useContext(ConfigContext);
  const apiPath: string = `${config.mbkHost}${config.mbkHostApi}`;

  const [orderId] = extractCurrentSimmaURL();

  const [formRecord, setFormRecord] = useState<Partial<SimmaOrderFormRecord>>();
  const [tableRecords, setTableRecords] = useState<
    SimmaDeliveryNotesTableRecord[]
  >([]);

  async function loadOrder(): Promise<ServiceSimmaRequestOrder> {
    return await getOrder(apiPath, orderId);
  }

  function mapOrder(order: ServiceSimmaRequestOrder): SimmaOrderFormRecord {
    return {
      orderId: order.id,

      deliveryTime: order.startdate,
      orderNumber: order.orderbarcode,
      supplierOrderid: order.orderid,
      concreteType: order.orderint.orderprod[0].productno,
      quantityAmount: order.orderint.orderprod[0].specqty,
      performanceAmount: order.orderint.orderprod[0].dischqty,
      additionalQuantity: order.orderint.orderprod[0].issurplus,
      status: order.status,
      cancelled: order.cancelled,

      activeVersion: {} as SimmaOrderFormRecord,
    };
  }

  async function loadDeliveryNotes(): Promise<ServiceDeliveryNote[]> {
    const fromDate: Date = new Date('2020-01-01');
    const toDate: Date = new Date('2100-01-01');
    const skip: number = 0;
    const take: number = 9999;
    const deliveryNotes: ServiceDeliveryNote[] = await getDeliveryNotes(
      apiPath,
      orderId,
      fromDate,
      toDate,
      skip,
      take
    );

    return deliveryNotes;
  }

  function mapDeliveryNotes(
    deliveryNotes: ServiceDeliveryNote[]
  ): SimmaDeliveryNotesTableRecord[] {
    const newTableRecords: SimmaDeliveryNotesTableRecord[] = [];

    for (const deliveryNote of deliveryNotes) {
      newTableRecords.push({
        key: deliveryNote.id,

        deliveryNoteId: deliveryNote.id,
        licensePlate: deliveryNote.dtData.vehlicpl,
        deliveryNoteNumber: deliveryNote.dtData.dtno,
        quantity: `${deliveryNote.dtData.dtDataDet[0].billqty} ${deliveryNote.dtData.dtDataDet[0].priceunit}`,
        loadingTime: deliveryNote.dtData.loadstart,
      });
    }

    return newTableRecords;
  }

  function refreshOrderForm(record: SimmaOrderFormRecord): void {
    setFormRecord(record);
  }

  function refreshDeliveryNotesTable(
    records: SimmaDeliveryNotesTableRecord[]
  ): void {
    setTableRecords(records);
  }

  async function loadAndRefresh(): Promise<void> {
    try {
      refreshOrderForm(mapOrder(await loadOrder()));
      refreshDeliveryNotesTable(mapDeliveryNotes(await loadDeliveryNotes()));

      message.success('Data loaded');
    } catch (error) {
      message.error(`Data loading failed with error: ${error}`);
    }
  }

  useEffect(() => {
    loadAndRefresh();
  }, [orderId]); // eslint-disable-line react-hooks/exhaustive-deps

  async function CreateDefaultDeliveryNotes_onClick(): Promise<void> {
    try {
      await createDefaultDeliveryNotes(apiPath, orderId);

      message.success('Default delivery notes created');

      loadAndRefresh();
    } catch (error) {
      message.error(
        `Creating default delivery notes failed with error: ${error}`
      );
    }
  }

  return (
    <Topbar>
      <Card title='SIMMA delivery notes'>
        <Space direction='vertical'>
          <Space direction='horizontal'>
            <Button
              type='primary'
              onClick={() => CreateDefaultDeliveryNotes_onClick()}
            >
              Create default delivery notes (count = QUANTITY / 8)
            </Button>

            <Link to={`/mocks/simma/order/${orderId}/deliverynotes/create`}>
              <Button type='primary'>Create custom delivery note(s)</Button>
            </Link>
          </Space>

          <Divider />

          <Space direction='vertical'>
            <Space direction='horizontal'>
              ORDER ID{' '}
              <div>
                <b>{formRecord?.orderId}</b>
              </div>
              <Tag
                color={`${getSimmaOrderStatusColor(
                  getSimmaOrderStatusText(
                    formRecord?.status,
                    formRecord?.cancelled
                  )
                )}`}
              >
                {getSimmaOrderStatusText(
                  formRecord?.status,
                  formRecord?.cancelled
                )}
              </Tag>
            </Space>

            <Divider />

            <Space direction='horizontal'>
              ORDER NUMBER{' '}
              <div>
                <b>{formRecord?.orderNumber}</b>
              </div>
              CONCRETE TYPE{' '}
              <div>
                <b>{formRecord?.concreteType}</b>
              </div>
              DELIVERY TIME{' '}
              <div>
                <b>{formatDate(formRecord?.deliveryTime)}</b>
              </div>
              QUANTITY/OUTPUT{' '}
              <div>
                <b>
                  {formRecord?.quantityAmount}&nbsp;㎥ /{' '}
                  {formRecord?.performanceAmount}&nbsp;㎥/h
                </b>
              </div>
            </Space>
          </Space>

          <Divider />

          <SimmaDeliveryNotesTable
            orderId={orderId}
            dataSource={tableRecords}
          />
        </Space>
      </Card>
    </Topbar>
  );
};

export default SimmaDeliveryNotes;
