import { Button, Card, Checkbox, Col, Divider, Form, Input, Row } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useContext, useState } from 'react';
import ErrorNotification from 'src/components/Notification/ErrorNotification';
import SuccessNotification from 'src/components/Notification/SuccessNotification';
import { ToolAdminContext } from 'src/providers/AdminToolProvider';

const AddCompanyRoles = (): JSX.Element => {

    const [checkedValues, setCheckedValues] = useState<CheckboxValueType[]>([]);
    const { operationalToolClient } = useContext(ToolAdminContext);

    const [form] = Form.useForm();

    const onCheckBoxChange = (checkedValues: CheckboxValueType[]) => {
        setCheckedValues(checkedValues);
      };

    async function addMemberCompanyroles() {

        try {
            const {
                userEmail,
                companyId,
            } = await form.validateFields();

            operationalToolClient.addCompanyMemberRoles(companyId, userEmail, checkedValues as string[])
            .then((response) => {
                if (response.isSuccess()) {

                    SuccessNotification({
                        message: 'company roles added successfully',
                        description: ''
                    });

                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message , description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
        }
        
        catch (e: any) {
            ErrorNotification({
                message: 'Form not filled correctly',
                description: 'Please, make sure that all the fields are filled correctly'
            });
        }

    }

    return (

        <Card title='Add company roles to existing user'>

        <Form form={form}>

            <Row gutter={[16, 16]}>

                <Col>
                    <Form.Item name={'companyId'} 
                            label="Company Id"
                            rules={[
                                {
                                    required: true,
                                    pattern: new RegExp(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi),
                                    message: (
                                        <span data-testid="errorCompanyName">
                                            Please insert a valid value
                                        </span>
                                    )
                                }
                        ]}>
                            <Input
                                type='companyId'
                                style={{ width: 300 }} 
                                placeholder={''}
                            />
                     </Form.Item>
                </Col>

                <Col>
                    <Form.Item name={'userEmail'} 
                            label="User email"
                            rules={[
                                {
                                    required: true,
                                    pattern: new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/),
                                    message: (
                                        <span data-testid="errorEmail">
                                            Please insert a valid email
                                        </span>
                                    )
                                }
                        ]}>
                            <Input
                                type='email'
                                style={{ width: 300 }} 
                                placeholder={''}
                            />
                     </Form.Item>
                </Col>

                <Col>
                    <Form.Item name={'roles'} 
                    label="roles"
                    >
                        <Checkbox.Group options={['CompanyAdmin', 'ProjectAdmin']}  onChange={onCheckBoxChange} />
                    </Form.Item>
                </Col>
            </Row>

            <Divider />

            <Row>

                <Button type='primary' onClick={() => addMemberCompanyroles()}>
                    Add roles
                </Button>

            </Row>
        </Form>
    </Card>
            
    );
};

export default AddCompanyRoles;

