import ResyncConcreteLoadByBcqdOrderId from 'src/busines-pages/orders/components/ResyncConcreteLoadByBcqdOrderId';
import ResyncConcreteLoadById from 'src/busines-pages/orders/components/ResyncConcreteLoadById';
import ResyncConcreteLoadByProjectId from 'src/busines-pages/orders/components/ResyncConcreteLoadByProjectId';
import TopBar from '../TopBar';


export default function GodelPage() {
  return (
    <TopBar>
      <ResyncConcreteLoadById />
      <ResyncConcreteLoadByBcqdOrderId />
      <ResyncConcreteLoadByProjectId />
    </TopBar>
  );
};
