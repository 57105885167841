import { BrowserRouter } from 'react-router-dom';
import AppMenu from './components/AppMenu/AppMenu';
import AppRouter from './providers/AppRouter';

function App(props: any): JSX.Element {
  return (
    <BrowserRouter>
      <AppMenu>
          <AppRouter data-testid='app-router' />
      </AppMenu>
      </BrowserRouter>
  );
}

export default App;
