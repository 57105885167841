import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Space, Button } from 'antd';
import { Link } from 'react-router-dom';

import { FunctionComponent } from 'react';
import { formatDate } from '../../providers/ConfigProvider';

import { SimmaDeliveryNotesTableRecord } from '../../models/SimmaDeliveryNotesTableRecord';

const SimmaDeliveryNotesTable: FunctionComponent<{
  orderId: string;
  dataSource: SimmaDeliveryNotesTableRecord[];
}> = (props) => {
  const records: SimmaDeliveryNotesTableRecord[] = props.dataSource;
  const orderId: string = props.orderId;

  const columns: ColumnsType<SimmaDeliveryNotesTableRecord> = [
    {
      title: 'License plate',
      dataIndex: 'licensePlate',
      key: 'License plate',
      render: (value: any, record: SimmaDeliveryNotesTableRecord) => (
        <Space direction='vertical'>{record.licensePlate?.toUpperCase()}</Space>
      ),
    },

    {
      title: 'Delivery note no',
      dataIndex: 'deliveryNoteNumber',
      key: 'Delivery note no',
      render: (value: any, record: SimmaDeliveryNotesTableRecord) => (
        <Space direction='vertical'>
          {record.deliveryNoteNumber?.toUpperCase()}
        </Space>
      ),
    },

    {
      title: 'Loading time',
      dataIndex: 'loadingTime',
      key: 'Loading time',
      render: (value: any, record: SimmaDeliveryNotesTableRecord) => (
        <Space direction='vertical'>{formatDate(record.loadingTime)}</Space>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'Quantity',
    },
    {
      title: 'Delivery note',
      dataIndex: 'Delivery note',
      key: 'Delivery note',
      render: (value: any, record: SimmaDeliveryNotesTableRecord) => (
        <Space size='middle'>
          <Link
            to={`/simma/order/${orderId}/deliverynote/${record.deliveryNoteId}`}
          >
            <Button type='link'>Edit</Button>
          </Link>
        </Space>
      ),
    },
  ];

  if (!records.length) {
    return <h1 data-testid='no-table' aria-hidden='true'></h1>;
  }

  return (
    <div data-testid='table'>
      <Table columns={columns} dataSource={records} />
    </div>
  );
};

export default SimmaDeliveryNotesTable;
