import { useContext, useEffect } from 'react';
import Topbar from '../components/TopBar';

import {
  Select,
  message,
  Divider,
  Card,
  Form,
  Input,
  Space,
  Button,
} from 'antd';

import {
  Config,
  ConfigContext,
  extractCurrentSauterURL,
} from '../providers/ConfigProvider';

import {
  getDeliveryNote,
  updateDeliveryNote,
  createCustomDeliveryNotes,
} from '../services/mbk-simma-mock/sauter/DeliveryNoteServices/DeliveryNoteServices';
import { ServiceDeliveryNote } from '../services/mbk-simma-mock/sauter/DeliveryNoteServices/models/ServiceDeliveryNote';

import { SauterDeliveryNoteFormRecord } from '../models/SauterDeliveryNoteFormRecord';

const { Option } = Select;

const SauterDeliveryNote = (props: any): JSX.Element => {
  const performCreateCustomDeliveryNotes: boolean =
    props.createCustomDeliveryNotes;

  const config: Config = useContext(ConfigContext);
  const apiPath: string = `${config.mbkHost}${config.mbkHostApi}`;
  const [orderId, deliveryNoteId] = extractCurrentSauterURL();

  const [form] = Form.useForm();

  let amountOfDeliveryNotes: number = 1;

  async function loadDeliveryNote(): Promise<ServiceDeliveryNote> {
    const deliveryNote: ServiceDeliveryNote = await getDeliveryNote(
      apiPath,
      deliveryNoteId
    );

    if (deliveryNote) {
      return deliveryNote;
    }

    throw new Error('Delivery note could not be loaded');
  }

  function mapDeliveryNote(
    deliveryNote: ServiceDeliveryNote
  ): SauterDeliveryNoteFormRecord {
    return {
      deliveryNoteNumber: String(deliveryNote.lsnummerfremd),
      licensePlate: deliveryNote.kennzeichen || '',
      loadingTime: deliveryNote.zeitstart,
      quantityAmount: String(deliveryNote.lademenge) || '',
      quantityUnit: String(deliveryNote.mengeeinheit) || '',
      werknummer: String(deliveryNote.werknr) || '',

      concreteTypeNumber: deliveryNote.artikelnr || '',
      compressiveStrengthClass: deliveryNote.festigkeitsklasse || '',
      strengthDevelopment: deliveryNote.festigkeitsentw || '',
      exposureClasses: deliveryNote.expositionsklasse || '',
      methodOfConcreteUseChlorideContent: deliveryNote.chloridklasse || '',
      consistencyClass: deliveryNote.konsistenz || '',
      waterCementRatio: String(deliveryNote.wzrezept) || '',
      maxAggregateSizeGrainSize: String(deliveryNote.groesstkorn) || '',
      densityClass: deliveryNote.rohdichteklasse || '',
      moistureClass: deliveryNote.feuchtigkeitsklasse || '',
      eigenschaften: deliveryNote.eigenschaften || '',
      eignung: deliveryNote.eignung || '',
      verarbeitbarkeitszeit: String(deliveryNote.verarbeitbarkeitszeit) || '',
      verarbeitbarkeitszeitEinheit:
        String(deliveryNote.verarbeitbarkeitszein) || '',
      ueberwachungsklasse: deliveryNote.ueberwachungsklasse || '',
    };
  }

  function refreshDeliveryNoteForm(record: SauterDeliveryNoteFormRecord): void {
    DeliveryNoteNumber_onChange(record.deliveryNoteNumber);
    LicensePlate_onChange(record.licensePlate);
    LoadingTime_onChange(record.loadingTime);
    QuantityAmount_onChange(record.quantityAmount);
    QuantityUnit_onChange(record.quantityUnit);
    werknummer_onChange(record.werknummer);

    ConcreteTypeNumber_onChange(record.concreteTypeNumber);
    compressiveStrengthClass_onChange(record.compressiveStrengthClass);
    strengthDevelopment_onChange(record.strengthDevelopment);
    ExposureClasses_onChange(record.exposureClasses);
    methodOfConcreteUseChlorideContent_onChange(
      record.methodOfConcreteUseChlorideContent
    );
    consistencyClass_onChange(record.consistencyClass);
    waterCementRatio_onChange(record.waterCementRatio);
    maxAggregateSizeGrainSize_onChange(record.maxAggregateSizeGrainSize);
    densityClass_onChange(record.densityClass);
    moistureClass_onChange(record.moistureClass);
    eigenschaften_onChange(record.eigenschaften);
    eignung_onChange(record.eignung);
    verarbeitbarkeitszeit_onChange(record.verarbeitbarkeitszeit);
    verarbeitbarkeitszeitEinheit_onChange(record.verarbeitbarkeitszeitEinheit);
    ueberwachungsklasse_onChange(record.ueberwachungsklasse);
  }

  async function loadAndRefresh(): Promise<void> {
    if (!performCreateCustomDeliveryNotes) {
      try {
        refreshDeliveryNoteForm(mapDeliveryNote(await loadDeliveryNote()));

        message.success('Data loaded');
      } catch (error) {
        message.error(`Data loading failed with error: ${error}`);
      }
    }
  }

  useEffect(() => {
    loadAndRefresh();
  }, [deliveryNoteId]); // eslint-disable-line react-hooks/exhaustive-deps

  async function perfromSaveOrCreateCustomDeliveryNotes(): Promise<void> {
    function map(deliveryNote: ServiceDeliveryNote): ServiceDeliveryNote {
      function map_IfNotEmptyOrSaving<K extends keyof ServiceDeliveryNote>(
        propertyName: K,
        value: any
      ): void {
        const mapForSaving: boolean = !performCreateCustomDeliveryNotes;

        if (mapForSaving || value) {
          deliveryNote[propertyName] = value;
        }
      }

      map_IfNotEmptyOrSaving(
        'lsnummerfremd',
        Number(form.getFieldValue('DeliveryNoteNumber'))
      );
      map_IfNotEmptyOrSaving('kennzeichen', form.getFieldValue('LicensePlate'));
      map_IfNotEmptyOrSaving('zeitstart', form.getFieldValue('LoadingTime'));
      map_IfNotEmptyOrSaving(
        'lademenge',
        Number(form.getFieldValue('QuantityAmount'))
      );
      map_IfNotEmptyOrSaving(
        'mengeeinheit',
        Number(form.getFieldValue('QuantityUnit'))
      );

      map_IfNotEmptyOrSaving(
        'werknr',
        Number(form.getFieldValue('werknummer'))
      );

      map_IfNotEmptyOrSaving(
        'artikelnr',
        form.getFieldValue('ConcreteTypeNumber')
      );
      map_IfNotEmptyOrSaving(
        'festigkeitsklasse',
        form.getFieldValue('compressiveStrengthClass')
      );
      map_IfNotEmptyOrSaving(
        'festigkeitsentw',
        form.getFieldValue('strengthDevelopment')
      );
      map_IfNotEmptyOrSaving(
        'expositionsklasse',
        form.getFieldValue('ExposureClasses')
      );
      map_IfNotEmptyOrSaving(
        'chloridklasse',
        form.getFieldValue('methodOfConcreteUseChlorideContent')
      );
      map_IfNotEmptyOrSaving(
        'konsistenz',
        form.getFieldValue('consistencyClass')
      );
      map_IfNotEmptyOrSaving(
        'wzrezept',
        Number(form.getFieldValue('waterCementRatio'))
      );
      map_IfNotEmptyOrSaving(
        'groesstkorn',
        Number(form.getFieldValue('maxAggregateSizeGrainSize'))
      );
      map_IfNotEmptyOrSaving(
        'rohdichteklasse',
        form.getFieldValue('densityClass')
      );
      map_IfNotEmptyOrSaving(
        'feuchtigkeitsklasse',
        form.getFieldValue('moistureClass')
      );
      map_IfNotEmptyOrSaving(
        'eigenschaften',
        form.getFieldValue('eigenschaften')
      );
      map_IfNotEmptyOrSaving('eignung', form.getFieldValue('eignung'));
      map_IfNotEmptyOrSaving(
        'verarbeitbarkeitszeit',
        Number(form.getFieldValue('verarbeitbarkeitszeit'))
      );
      map_IfNotEmptyOrSaving(
        'verarbeitbarkeitszein',
        Number(form.getFieldValue('verarbeitbarkeitszeitEinheit'))
      );
      map_IfNotEmptyOrSaving(
        'ueberwachungsklasse',
        form.getFieldValue('ueberwachungsklasse')
      );

      return deliveryNote;
    }

    if (performCreateCustomDeliveryNotes) {
      try {
        const deliveryNotes: ServiceDeliveryNote[] = [];

        for (let i: number = 0; i < amountOfDeliveryNotes; ++i) {
          deliveryNotes.push(map({} as ServiceDeliveryNote));
        }

        await createCustomDeliveryNotes(apiPath, orderId, deliveryNotes);

        message.success('Custom delivery note(s) successfully created');
      } catch (error) {
        message.error(
          `Creating custom delivery note(s) failed with error: ${error}`
        );
      }
    } else {
      try {
        const deliveryNote: ServiceDeliveryNote = map(await loadDeliveryNote());

        await updateDeliveryNote(apiPath, deliveryNoteId, deliveryNote);

        message.success('Save delivery note succeeded');
      } catch (error) {
        message.error(`Save delivery note failed with error: ${error}`);
      }
    }
  }

  async function Save_onClick(): Promise<void> {
    return perfromSaveOrCreateCustomDeliveryNotes();
  }

  async function AmountOfDeliveryNotes_onClick(value: string): Promise<void> {
    amountOfDeliveryNotes = Number(value);
  }

  async function CreateCustomDeliveryNotes_onClick(): Promise<void> {
    return perfromSaveOrCreateCustomDeliveryNotes();
  }

  function DeliveryNoteNumber_onChange(deliveryNoteNumber: string): void {
    form.setFieldsValue({ DeliveryNoteNumber: deliveryNoteNumber });
  }

  function LicensePlate_onChange(licensePlate: string): void {
    form.setFieldsValue({ LicensePlate: licensePlate });
  }

  function LoadingTime_onChange(value: string): void {
    form.setFieldsValue({ LoadingTime: value });
  }

  function QuantityAmount_onChange(quantityAmount: string): void {
    form.setFieldsValue({ QuantityAmount: quantityAmount });
  }

  function QuantityUnit_onChange(quantityUnit: string): void {
    form.setFieldsValue({ QuantityUnit: quantityUnit });
  }

  function werknummer_onChange(werknummer: string): void {
    form.setFieldsValue({ werknummer });
  }

  function ConcreteTypeNumber_onChange(concreteTypeNumber: string): void {
    form.setFieldsValue({ ConcreteTypeNumber: concreteTypeNumber });
  }

  function compressiveStrengthClass_onChange(
    compressiveStrengthClass: string
  ): void {
    form.setFieldsValue({ compressiveStrengthClass });
  }

  function strengthDevelopment_onChange(strengthDevelopment: string): void {
    form.setFieldsValue({ strengthDevelopment });
  }

  function ExposureClasses_onChange(exposureClasses: string): void {
    form.setFieldsValue({ ExposureClasses: exposureClasses });
  }

  function methodOfConcreteUseChlorideContent_onChange(
    methodOfConcreteUseChlorideContent: string
  ): void {
    form.setFieldsValue({ methodOfConcreteUseChlorideContent });
  }

  function consistencyClass_onChange(consistencyClass: string): void {
    form.setFieldsValue({ consistencyClass });
  }

  function waterCementRatio_onChange(waterCementRatio: string): void {
    form.setFieldsValue({ waterCementRatio });
  }

  function maxAggregateSizeGrainSize_onChange(
    maxAggregateSizeGrainSize: string
  ): void {
    form.setFieldsValue({ maxAggregateSizeGrainSize });
  }

  function densityClass_onChange(densityClass: string): void {
    form.setFieldsValue({ densityClass });
  }

  function moistureClass_onChange(moistureClass: string): void {
    form.setFieldsValue({ moistureClass });
  }

  function eigenschaften_onChange(eigenschaften: string): void {
    form.setFieldsValue({ eigenschaften });
  }

  function eignung_onChange(eignung: string): void {
    form.setFieldsValue({ eignung });
  }

  function verarbeitbarkeitszeit_onChange(verarbeitbarkeitszeit: string): void {
    form.setFieldsValue({ verarbeitbarkeitszeit });
  }

  function verarbeitbarkeitszeitEinheit_onChange(
    verarbeitbarkeitszeitEinheit: string
  ): void {
    form.setFieldsValue({ verarbeitbarkeitszeitEinheit });
  }

  function ueberwachungsklasse_onChange(ueberwachungsklasse: string): void {
    form.setFieldsValue({ ueberwachungsklasse });
  }

  const title: string = performCreateCustomDeliveryNotes
    ? 'Create custom Sauter delivery note(s)'
    : 'Edit Sauter delivery note';

  return (
    <Topbar>
      <Card title={title}>
        <Space direction='vertical'>
          <Space direction='horizontal'>
            {performCreateCustomDeliveryNotes ? (
              <div>
                Select amount of delivery notes:&nbsp;
                <Select
                  defaultValue={`${amountOfDeliveryNotes}`}
                  onChange={AmountOfDeliveryNotes_onClick}
                >
                  <Option value='1'>1</Option>
                  <Option value='2'>2</Option>
                  <Option value='3'>3</Option>
                  <Option value='5'>5</Option>
                  <Option value='10'>10</Option>
                </Select>
              </div>
            ) : (
              <Button type='primary' onClick={() => Save_onClick()}>
                Save
              </Button>
            )}
          </Space>

          <Divider />

          <Form form={form}>
            <Space direction='horizontal'>
              <Form.Item name='DeliveryNoteNumber' label='DELIVERY NOTE NUMBER'>
                <Input
                  onChange={(e: any) =>
                    DeliveryNoteNumber_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item name='LicensePlate' label='LICENSE PLATE'>
                <Input
                  onChange={(e: any) => LicensePlate_onChange(e.target.value)}
                />
              </Form.Item>

              <Form.Item name='LoadingTime' label='LOADING TIME'>
                <Input
                  onChange={(e: any) => LoadingTime_onChange(e.target.value)}
                />
              </Form.Item>
            </Space>
            <Space direction='horizontal'>
              <Form.Item name='QuantityAmount' label='QUANTITY'>
                <Input
                  onChange={(e: any) => QuantityAmount_onChange(e.target.value)}
                />
              </Form.Item>

              <Form.Item name='QuantityUnit' label='UNIT'>
                <Input
                  onChange={(e: any) => QuantityUnit_onChange(e.target.value)}
                />
              </Form.Item>

              <Form.Item name='werknummer' label='WERKNUMMER'>
                <Input
                  onChange={(e: any) => werknummer_onChange(e.target.value)}
                />
              </Form.Item>
            </Space>

            <Divider>Concrete information</Divider>

            <Space direction='vertical'>
              <Form.Item name='ConcreteTypeNumber' label='CONCRETE TYPE NUMBER'>
                <Input
                  onChange={(e: any) =>
                    ConcreteTypeNumber_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item
                name='compressiveStrengthClass'
                label='COMPRESSIVE STRENGTH CLASS'
              >
                <Input
                  onChange={(e: any) =>
                    compressiveStrengthClass_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item
                name='strengthDevelopment'
                label='STRENGTH DEVELOPMENT'
              >
                <Input
                  onChange={(e: any) =>
                    strengthDevelopment_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item name='ExposureClasses' label='EXPOSURE CLASSES'>
                <Input
                  onChange={(e: any) =>
                    ExposureClasses_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item
                name='methodOfConcreteUseChlorideContent'
                label='METHOD OF CONCRETE USE / CHLORIDE CONTENT'
              >
                <Input
                  onChange={(e: any) =>
                    methodOfConcreteUseChlorideContent_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item name='consistencyClass' label='CONSISTENCY CLASS'>
                <Input
                  onChange={(e: any) =>
                    consistencyClass_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item
                name='waterCementRatio'
                label='WATER / CEMENT RATIO (EQ)'
              >
                <Input
                  onChange={(e: any) =>
                    waterCementRatio_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item
                name='maxAggregateSizeGrainSize'
                label='MAX AGGREGATE SIZE / GRAIN SIZE'
              >
                <Input
                  onChange={(e: any) =>
                    maxAggregateSizeGrainSize_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item name='densityClass' label='DENSITY CLASS'>
                <Input
                  onChange={(e: any) => densityClass_onChange(e.target.value)}
                />
              </Form.Item>

              <Form.Item name='moistureClass' label='MOISTURE CLASS'>
                <Input
                  onChange={(e: any) => moistureClass_onChange(e.target.value)}
                />
              </Form.Item>

              <Form.Item name='eigenschaften' label='EIGENSCHAFTEN'>
                <Input
                  onChange={(e: any) => eigenschaften_onChange(e.target.value)}
                />
              </Form.Item>

              <Form.Item name='eignung' label='EIGNUNG'>
                <Input
                  onChange={(e: any) => eignung_onChange(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                name='verarbeitbarkeitszeit'
                label='VERARBEITBARKEITSZEIT'
              >
                <Input
                  onChange={(e: any) =>
                    verarbeitbarkeitszeit_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item
                name='verarbeitbarkeitszeitEinheit'
                label='VERARBEITBARKEITSZEIT EINHEIT'
              >
                <Input
                  onChange={(e: any) =>
                    verarbeitbarkeitszeitEinheit_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item name='ueberwachungsklasse' label='ÜBERWACHUNGSKLASSE'>
                <Input
                  onChange={(e: any) =>
                    ueberwachungsklasse_onChange(e.target.value)
                  }
                />
              </Form.Item>
            </Space>
          </Form>
        </Space>

        <Space direction='vertical'>
          <Space direction='horizontal'>
            {performCreateCustomDeliveryNotes ? (
              <Button
                type='primary'
                onClick={() => CreateCustomDeliveryNotes_onClick()}
              >
                Create delivery note(s)
              </Button>
            ) : (
              <Button type='primary' onClick={() => Save_onClick()}>
                Save
              </Button>
            )}
          </Space>
        </Space>
      </Card>
    </Topbar>
  );
};

export default SauterDeliveryNote;
