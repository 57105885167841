import { Button, Card, Space } from 'antd';
import { Link } from 'react-router-dom';

const SupplierManagement = (): JSX.Element => {

    return (

    <Card title='Please select the concrete supplier'>
      <Space direction='horizontal'>
        <Link to={`/supplier/dsa`}>
          <Button>DSA</Button>
        </Link>
      </Space>
      &nbsp;
      <Space direction='horizontal'>
        <Link to={`/supplier/godel`}>
          <Button>Godel</Button>
        </Link>
      </Space>
    </Card>
    );
};

export default SupplierManagement;


