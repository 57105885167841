import './styles/index.css';
import './styles/styles.less';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ToolAdminProvider } from './providers/AdminToolProvider';
import ConfigProvider from './providers/ConfigProvider';
import IdentityProvider from './providers/IdentityProvider';

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider>
      <IdentityProvider>
        <ToolAdminProvider>
          <App />
        </ToolAdminProvider>
      </IdentityProvider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
