import { MemberInput, UserInput } from '@nexploretechnology/bcqd-client';
import { Button, Card, Checkbox, Col, Divider, Form, Input, Row, Switch } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useContext, useState } from 'react';
import ErrorNotification from 'src/components/Notification/ErrorNotification';
import SuccessNotification from 'src/components/Notification/SuccessNotification';
import { ToolAdminContext } from 'src/providers/AdminToolProvider';

const CreateCompanyMember = (): JSX.Element => {

    const [checkedValues, setCheckedValues] = useState<CheckboxValueType[]>([]);
    const { operationalToolClient } = useContext(ToolAdminContext);

    const [form] = Form.useForm();

    const onCheckBoxChange = (checkedValues: CheckboxValueType[]) => {
        setCheckedValues(checkedValues);
      };

    async function addMemberToCompany() {

        try {
            const {
                companyId,
                userName,
                userEmail,
                isUserIntern
            } = await form.validateFields();

            const userInput: UserInput = {
                userEmail: userEmail,
                name: userName,
                isUserIntern
            };

            const memberInput: MemberInput = {
                user: userInput,
                companyRoles: checkedValues as string[]
            };

            operationalToolClient.addCompanyMember(companyId, memberInput)
            .then((response) => {
                if (response.isSuccess()) {
                    
                    SuccessNotification({
                        message: 'member created successfully',
                        description: ''
                    });

                    const member = response.getEntity() as unknown as any;

                    if (member?.roles?.alreadyOwnedCompanyRoles?.length) {
                      ErrorNotification({
                          message: `The user already owns ${member.roles.alreadyOwnedCompanyRoles.join(', ')} roles`,
                          description: ''
                      });
                  }
                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message , description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
        }
        
        catch (e: any) {
            ErrorNotification({
                message: 'Form not filled correctly',
                description: 'Please, make sure that all the fields are filled correctly'
            });
        }

    }


    return (
            <Card title='Create company member'>

                <Form form={form}>
                    <Row>
                        <Form.Item name="isUserIntern" valuePropName="checked" initialValue={false} label="Intern User">
                            <Switch data-testid="isUserIntern" />
                        </Form.Item>
                    </Row>


                    <Row gutter={[16, 16]}>

                        <Col>
                            <Form.Item name={'companyId'} 
                                label="Company Id"
                                rules={[
                                    {
                                        required: true,
                                        pattern: new RegExp(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi),
                                        message: (
                                            <span data-testid="errorCompanyId">
                                                Please insert a valid value
                                            </span>
                                        )
                                    }
                                ]}>

                                <Input
                                    style={{ width: 300 }} 
                                    placeholder={''}
                                />
                            </Form.Item>

                        </Col>

                        <Col>
                            <Form.Item name={'userName'} 
                                label="User Name"
                                rules={[
                                    {
                                        required: true,
                                        pattern: new RegExp(/^[^\s]+(\s+[^\s]+)*$/),
                                        message: (
                                            <span data-testid="errorCompanyName">
                                                Please insert a valid value
                                            </span>
                                        )
                                    }
                                ]}
                            >
                                <Input
                                    style={{ width: 300 }} 
                                    placeholder={''}
                                    
                                />
                            </Form.Item>

                        </Col>

                        <Col>
                            <Form.Item name={'userEmail'} 
                                label="User email"
                                rules={[
                                    {
                                        required: true,
                                        pattern: new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/),
                                        message: (
                                            <span data-testid="errorEmail">
                                                Please insert a valid email
                                            </span>
                                        )
                                    }
                                ]}
                            >
                                <Input
                                    type='email'
                                    style={{ width: 300 }} 
                                    placeholder={''}
                                />
                            </Form.Item>

                        </Col>

                    </Row>

                    <Row>
                        <Form.Item name={'roles'} 
                        label="roles"
                         >
                                    <Checkbox.Group options={['CompanyAdmin', 'ProjectAdmin']}  onChange={onCheckBoxChange} />
                        </Form.Item>
                    </Row>

                    <Divider />

                    <Row>

                        <Button type='primary' onClick={() => addMemberToCompany()}>
                            Create member
                        </Button>

                    </Row>
                </Form>
            </Card>
            
    );
};

export default CreateCompanyMember;

