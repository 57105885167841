import { Space } from 'antd';
import ProjectOrdersTable from './components/ProjectOrdersTable';
import ResetConcreteLoadRetriesCounter from './components/ResetConcreteLoadRetriesCounter';
import ResetOrderRetriesCounter from './components/ResetOrderRetriesCounter';
import SyncOrder from './components/SyncOrder';
import SyncProject from './components/SyncProject';
import ResyncConcreteLoadById from './components/ResyncConcreteLoadById';
import ResyncConcreteLoadByBcqdOrderId from './components/ResyncConcreteLoadByBcqdOrderId';
import ResyncConcreteLoadByProjectId from './components/ResyncConcreteLoadByProjectId';

const OrderManagement = (): JSX.Element => {
  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <ProjectOrdersTable />
      <SyncProject />
      <SyncOrder />
      <ResetOrderRetriesCounter />
      <ResetConcreteLoadRetriesCounter />
      <ResyncConcreteLoadById />
      <ResyncConcreteLoadByBcqdOrderId />
      <ResyncConcreteLoadByProjectId />
    </Space>
  );
};

export default OrderManagement;
