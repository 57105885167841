import emptySvg from '../../assets/images/emptystate_illu.svg';

const NotFound = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '10%' }}>
            <p style={{ fontWeight: 'bold', color: '#1F3855' }}>
               Page not found
            </p>
            <img alt="not-found" src={emptySvg}></img>
        </div>
    );
};

export default NotFound;
