import { useContext, useEffect } from 'react';
import Topbar from '../components/TopBar';

import {
  Select,
  Modal,
  message,
  Divider,
  Card,
  Form,
  DatePicker,
  Input,
  Space,
  Button,
} from 'antd';

import {
  Config,
  ConfigContext,
  extractCurrentSimmaURL,
} from '../providers/ConfigProvider';

import moment from 'moment';

import {
  getDeliveryNotes,
  updateDeliveryNote,
  createCustomDeliveryNotes,
} from '../services/mbk-simma-mock/simma/DeliveryNoteServices/DeliveryNoteServices';
import {
  ServiceDeliveryNote,
  DeliveryNoteProduction,
} from '../services/mbk-simma-mock/simma/DeliveryNoteServices/models/ServiceDeliveryNote';

import {
  SimmaDeliveryNoteFormRecord,
  Material,
} from '../models/SimmaDeliveryNoteFormRecord';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const SimmaDeliveryNote = (props: any): JSX.Element => {
  const performCreateCustomDeliveryNotes: boolean =
    props.createCustomDeliveryNotes;

  const config: Config = useContext(ConfigContext);
  const apiPath: string = `${config.mbkHost}${config.mbkHostApi}`;

  const [orderId, deliveryNoteId] = extractCurrentSimmaURL();

  const [form] = Form.useForm();
  const [formMaterials] = Form.useForm();

  let amountOfDeliveryNotes: number = 1;

  function MaterialsList(materialsListProps: any): any {
    function matdesc_onChange(value: string, index: number): void {
      const formList = formMaterials.getFieldsValue();
      const row = formList.rows[index];
      row.matdesc = value;
      formMaterials.setFieldsValue(formList);
    }

    function mattype_onChange(value: string, index: number): void {
      const formList = formMaterials.getFieldsValue();
      const row = formList.rows[index];
      row.mattype = value;
      formMaterials.setFieldsValue(formList);
    }

    function matcode_onChange(value: string, index: number): void {
      const formList = formMaterials.getFieldsValue();
      const row = formList.rows[index];
      row.matcode = value;
      formMaterials.setFieldsValue(formList);
    }

    function setval_onChange(value: string, index: number): void {
      const formList = formMaterials.getFieldsValue();
      const row = formList.rows[index];
      row.setval = value;
      formMaterials.setFieldsValue(formList);
    }

    function actval_onChange(value: string, index: number): void {
      const formList = formMaterials.getFieldsValue();
      const row = formList.rows[index];
      row.actval = value;
      formMaterials.setFieldsValue(formList);
    }

    function unit_onChange(value: string, index: number): void {
      const formList = formMaterials.getFieldsValue();
      const row = formList.rows[index];
      row.unit = value;
      formMaterials.setFieldsValue(formList);
    }

    function moist_onChange(value: string, index: number): void {
      const formList = formMaterials.getFieldsValue();
      const row = formList.rows[index];
      row.moist = value;
      formMaterials.setFieldsValue(formList);
    }

    async function Examples_onClick(): Promise<void> {
      Modal.info({
        title: 'Mapping examples',
        content: (
          <div>
            Cement: matcode = 1 and mattype = 0<br></br>
            Additive: matcode = 1 and mattype = 1 or 2<br></br>
            Aggregate: matcode = 0 or 5<br></br>
            Water: matcode = 2<br></br>
            Admixture: matcode = 3<br></br>
            Other: matcode = 4<br></br>
          </div>
        ),
        onOk(): any {},
      });
    }

    return (
      <Form.List name='rows'>
        {(fields, { add, remove }) => {
          return (
            <div>
              <Space direction='vertical'>
                <Button onClick={() => Examples_onClick()}>Examples</Button>

                {fields.map((field, index) => (
                  <div key={field.key}>
                    <Space direction='vertical'>
                      <Space direction='horizontal'>
                        <Form.Item name={[field.name, 'matdesc']}>
                          <Input
                            addonBefore='Desc'
                            onChange={(e: any) =>
                              matdesc_onChange(e.target.value, index)
                            }
                          />
                        </Form.Item>

                        <Form.Item name={[field.name, 'mattype']}>
                          <Input
                            addonBefore='Type'
                            onChange={(e: any) =>
                              mattype_onChange(e.target.value, index)
                            }
                          />
                        </Form.Item>

                        <Form.Item name={[field.name, 'matcode']}>
                          <Input
                            addonBefore='Code'
                            onChange={(e: any) =>
                              matcode_onChange(e.target.value, index)
                            }
                          />
                        </Form.Item>

                        <Form.Item name={[field.name, 'setval']}>
                          <Input
                            addonBefore='Set'
                            onChange={(e: any) =>
                              setval_onChange(e.target.value, index)
                            }
                          />
                        </Form.Item>

                        <Form.Item name={[field.name, 'actval']}>
                          <Input
                            addonBefore='Act'
                            onChange={(e: any) =>
                              actval_onChange(e.target.value, index)
                            }
                          />
                        </Form.Item>

                        <Form.Item name={[field.name, 'moist']}>
                          <Input
                            addonBefore='Moisture'
                            onChange={(e: any) =>
                              moist_onChange(e.target.value, index)
                            }
                          />
                        </Form.Item>

                        <Form.Item name={[field.name, 'unit']}>
                          <Input
                            addonBefore='Unit'
                            onChange={(e: any) =>
                              unit_onChange(e.target.value, index)
                            }
                          />
                        </Form.Item>

                        <Form.Item name={[field.name, 'dummyForButton']}>
                          <Button
                            onClick={() => remove(field.name)}
                            icon={<MinusCircleOutlined />}
                          ></Button>
                        </Form.Item>
                      </Space>
                    </Space>
                  </div>
                ))}
              </Space>

              <Divider />

              <Form.Item>
                <Button type='dashed' onClick={() => add()}>
                  <PlusOutlined /> New Element
                </Button>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    );
  }

  async function loadDeliveryNote(): Promise<ServiceDeliveryNote> {
    const fromDate: Date = new Date('2020-01-01');
    const toDate: Date = new Date('2100-01-01');
    const skip: number = 0;
    const take: number = 9999;
    const deliveryNotes: ServiceDeliveryNote[] = await getDeliveryNotes(
      apiPath,
      orderId,
      fromDate,
      toDate,
      skip,
      take
    );

    const deliveryNote: ServiceDeliveryNote | undefined = deliveryNotes.find(
      (serviceDeliveryNote: ServiceDeliveryNote) =>
        serviceDeliveryNote.id === deliveryNoteId
    );
    if (deliveryNote) {
      return deliveryNote;
    }

    throw new Error('Delivery note could not be loaded');
  }

  function mapDeliveryNote(
    deliveryNote: ServiceDeliveryNote
  ): SimmaDeliveryNoteFormRecord {
    return {
      deliveryNoteNumber: deliveryNote.dtNo,
      licensePlate: deliveryNote.dtData.vehlicpl,
      loadingTime: new Date(deliveryNote.dtData.loadstart),
      quantityAmount: deliveryNote.dtData.dtDataDet[0].billqty,
      quantityUnit: deliveryNote.dtData.dtDataDet[0].priceunit,
      plantNumber: deliveryNote.dtData.plantno,
      plantDescription: deliveryNote.dtData.plantdesc,

      concreteTypeNumber: deliveryNote.dtData.dtDataDet[0].productno,
      compressiveStrengthClass: deliveryNote.dtData.strength,
      strengthDevelopment: deliveryNote.dtData.strengthdev,
      exposureClasses: deliveryNote.dtData.expclass,
      methodOfConcreteUseChlorideContent: deliveryNote.dtData.chlorclass,
      consistencyClass: deliveryNote.dtData.consclass,
      waterCementRatio: deliveryNote.dtData.dtwcact,
      maxAggregateSizeGrainSize: deliveryNote.dtData.aggsize,
      moistureClass: deliveryNote.dtData.moistclass,
      specialProperties: deliveryNote.dtData.prop,
      mixRemark: deliveryNote.dtData.mixremark,
      reuseTime: deliveryNote.dtData.reusetime,

      materials: deliveryNote.dtData.dtProduction,
    };
  }

  function refreshDeliveryNoteForm(record: SimmaDeliveryNoteFormRecord): void {
    DeliveryNoteNumber_onChange(record.deliveryNoteNumber);
    LicensePlate_onChange(record.licensePlate);
    LoadingTime_onChange(record.loadingTime);
    QuantityAmount_onChange(record.quantityAmount);
    QuantityUnit_onChange(record.quantityUnit);
    PlantNumber_onChange(record.plantNumber);
    PlantDescription_onChange(record.plantDescription);

    ConcreteTypeNumber_onChange(record.concreteTypeNumber);
    compressiveStrengthClass_onChange(record.compressiveStrengthClass);
    strengthDevelopment_onChange(record.strengthDevelopment);
    ExposureClasses_onChange(record.exposureClasses?.join(' '));
    methodOfConcreteUseChlorideContent_onChange(
      record.methodOfConcreteUseChlorideContent
    );
    consistencyClass_onChange(record.consistencyClass);
    waterCementRatio_onChange(record.waterCementRatio);
    maxAggregateSizeGrainSize_onChange(record.maxAggregateSizeGrainSize);
    moistureClass_onChange(record.moistureClass);
    specialProperties_onChange(record.specialProperties?.join(' '));
    mixRemark_onChange(record.mixRemark);
    reuseTime_onChange(record.reuseTime);

    formMaterials.setFieldsValue({ rows: record.materials });
  }

  async function loadAndRefresh(): Promise<void> {
    if (!performCreateCustomDeliveryNotes) {
      try {
        refreshDeliveryNoteForm(mapDeliveryNote(await loadDeliveryNote()));

        message.success('Data loaded');
      } catch (error) {
        message.error(`Data loading failed with error: ${error}`);
      }
    }
  }

  useEffect(() => {
    loadAndRefresh();
  }, [deliveryNoteId]); // eslint-disable-line react-hooks/exhaustive-deps

  async function perfromSaveOrCreateCustomDeliveryNotes(): Promise<void> {
    function map(deliveryNote: ServiceDeliveryNote): ServiceDeliveryNote {
      const mapForSaving: boolean = !performCreateCustomDeliveryNotes;

      if (mapForSaving || form.getFieldValue('DeliveryNoteNumber')) {
        deliveryNote.dtNo = form.getFieldValue('DeliveryNoteNumber');
      }
      if (mapForSaving || form.getFieldValue('LicensePlate')) {
        deliveryNote.dtData.vehlicpl = form.getFieldValue('LicensePlate');
      }
      if (mapForSaving || form.getFieldValue('LoadingTime')) {
        deliveryNote.dtData.loadstart = form
          .getFieldValue('LoadingTime')
          .toDate();
      }
      if (mapForSaving || form.getFieldValue('QuantityAmount')) {
        deliveryNote.dtData.dtDataDet[0].billqty =
          form.getFieldValue('QuantityAmount');
      }
      if (mapForSaving || form.getFieldValue('QuantityUnit')) {
        deliveryNote.dtData.dtDataDet[0].priceunit =
          form.getFieldValue('QuantityUnit');
      }
      if (mapForSaving || form.getFieldValue('PlantNumber')) {
        deliveryNote.dtData.plantno = form.getFieldValue('PlantNumber');
      }
      if (mapForSaving || form.getFieldValue('PlantDescription')) {
        deliveryNote.dtData.plantdesc = form.getFieldValue('PlantDescription');
      }

      if (mapForSaving || form.getFieldValue('ConcreteTypeNumber')) {
        deliveryNote.dtData.dtDataDet[0].productno =
          form.getFieldValue('ConcreteTypeNumber');
      }
      if (mapForSaving || form.getFieldValue('ExposureClasses')) {
        deliveryNote.dtData.expclass = form
          .getFieldValue('ExposureClasses')
          ?.split(' ');
      }

      if (mapForSaving || form.getFieldValue('compressiveStrengthClass')) {
        deliveryNote.dtData.strength = form.getFieldValue(
          'compressiveStrengthClass'
        );
      }
      if (mapForSaving || form.getFieldValue('strengthDevelopment')) {
        deliveryNote.dtData.strengthdev = form.getFieldValue(
          'strengthDevelopment'
        );
      }
      if (
        mapForSaving ||
        form.getFieldValue('methodOfConcreteUseChlorideContent')
      ) {
        deliveryNote.dtData.chlorclass = form.getFieldValue(
          'methodOfConcreteUseChlorideContent'
        );
      }
      if (mapForSaving || form.getFieldValue('consistencyClass')) {
        deliveryNote.dtData.consclass = form.getFieldValue('consistencyClass');
      }
      if (mapForSaving || form.getFieldValue('waterCementRatio')) {
        deliveryNote.dtData.dtwcact = form.getFieldValue('waterCementRatio');
      }
      if (mapForSaving || form.getFieldValue('maxAggregateSizeGrainSize')) {
        deliveryNote.dtData.aggsize = form.getFieldValue(
          'maxAggregateSizeGrainSize'
        );
      }
      if (mapForSaving || form.getFieldValue('moistureClass')) {
        deliveryNote.dtData.moistclass = form.getFieldValue('moistureClass');
      }
      if (mapForSaving || form.getFieldValue('specialProperties')) {
        deliveryNote.dtData.prop = form
          .getFieldValue('specialProperties')
          ?.split(' ');
      }
      if (mapForSaving || form.getFieldValue('mixRemark')) {
        deliveryNote.dtData.mixremark = form.getFieldValue('mixRemark');
      }
      if (mapForSaving || form.getFieldValue('reuseTime')) {
        deliveryNote.dtData.reusetime = form.getFieldValue('reuseTime');
      }

      const formList = formMaterials.getFieldsValue();

      deliveryNote.dtData.dtProduction = [];
      formList.rows?.forEach((row: Material) => {
        const deliveryNoteProduction: DeliveryNoteProduction = {
          id: 'test id',
          materialno: 'test materialno',
        } as DeliveryNoteProduction;

        if (mapForSaving || row.matdesc) {
          deliveryNoteProduction.matdesc = row.matdesc;
        }
        if (mapForSaving || row.mattype) {
          deliveryNoteProduction.mattype = row.mattype;
        }
        if (mapForSaving || row.matcode) {
          deliveryNoteProduction.matcode = row.matcode;
        }
        if (mapForSaving || row.actval) {
          deliveryNoteProduction.actval = row.actval;
        }
        if (mapForSaving || row.setval) {
          deliveryNoteProduction.setval = row.setval;
        }
        if (mapForSaving || row.unit) {
          deliveryNoteProduction.unit = row.unit;
        }
        if (mapForSaving || row.moist) {
          deliveryNoteProduction.moist = row.moist;
        }

        deliveryNote.dtData.dtProduction.push(deliveryNoteProduction);
      });

      return deliveryNote;
    }

    if (performCreateCustomDeliveryNotes) {
      try {
        const deliveryNotes: ServiceDeliveryNote[] = [];

        for (let i: number = 0; i < amountOfDeliveryNotes; ++i) {
          deliveryNotes.push(
            map({ dtData: { dtDataDet: [{}] } } as ServiceDeliveryNote)
          );
        }

        await createCustomDeliveryNotes(apiPath, orderId, deliveryNotes);

        message.success('Custom delivery note(s) successfully created');
      } catch (error) {
        message.error(
          `Creating custom delivery note(s) failed with error: ${error}`
        );
      }
    } else {
      try {
        const deliveryNote: ServiceDeliveryNote = map(await loadDeliveryNote());

        await updateDeliveryNote(apiPath, deliveryNoteId, deliveryNote);

        message.success('Save delivery note succeeded');
      } catch (error) {
        message.error(`Save delivery note failed with error: ${error}`);
      }
    }
  }

  async function Save_onClick(): Promise<void> {
    return perfromSaveOrCreateCustomDeliveryNotes();
  }

  async function AmountOfDeliveryNotes_onClick(value: string): Promise<void> {
    amountOfDeliveryNotes = Number(value);
  }

  async function CreateCustomDeliveryNotes_onClick(): Promise<void> {
    return perfromSaveOrCreateCustomDeliveryNotes();
  }

  function DeliveryNoteNumber_onChange(deliveryNoteNumber: string): void {
    form.setFieldsValue({ DeliveryNoteNumber: deliveryNoteNumber });
  }

  function LicensePlate_onChange(licensePlate: string): void {
    form.setFieldsValue({ LicensePlate: licensePlate });
  }

  function LoadingTime_onChange(date: Date): void {
    form.setFieldsValue({ LoadingTime: moment(date) });
  }

  function QuantityAmount_onChange(quantityAmount: string | number): void {
    form.setFieldsValue({ QuantityAmount: quantityAmount });
  }

  function QuantityUnit_onChange(quantityUnit: string): void {
    form.setFieldsValue({ QuantityUnit: quantityUnit });
  }

  function PlantNumber_onChange(plantNumber: string | number): void {
    form.setFieldsValue({ PlantNumber: plantNumber });
  }

  function PlantDescription_onChange(plantDescription: string): void {
    form.setFieldsValue({ PlantDescription: plantDescription });
  }

  function ConcreteTypeNumber_onChange(concreteTypeNumber: string): void {
    form.setFieldsValue({ ConcreteTypeNumber: concreteTypeNumber });
  }

  function compressiveStrengthClass_onChange(
    compressiveStrengthClass: string
  ): void {
    form.setFieldsValue({ compressiveStrengthClass });
  }

  function strengthDevelopment_onChange(strengthDevelopment: string): void {
    form.setFieldsValue({ strengthDevelopment });
  }

  function ExposureClasses_onChange(exposureClasses: string): void {
    form.setFieldsValue({ ExposureClasses: exposureClasses });
  }

  function methodOfConcreteUseChlorideContent_onChange(
    methodOfConcreteUseChlorideContent: string
  ): void {
    form.setFieldsValue({ methodOfConcreteUseChlorideContent });
  }

  function consistencyClass_onChange(consistencyClass: string): void {
    form.setFieldsValue({ consistencyClass });
  }

  function waterCementRatio_onChange(waterCementRatio: number): void {
    form.setFieldsValue({ waterCementRatio });
  }

  function maxAggregateSizeGrainSize_onChange(
    maxAggregateSizeGrainSize: number
  ): void {
    form.setFieldsValue({ maxAggregateSizeGrainSize });
  }

  function moistureClass_onChange(moistureClass: string | undefined): void {
    form.setFieldsValue({ moistureClass });
  }

  function specialProperties_onChange(specialProperties: string): void {
    form.setFieldsValue({ specialProperties });
  }

  function mixRemark_onChange(mixRemark: string): void {
    form.setFieldsValue({ mixRemark });
  }

  function reuseTime_onChange(reuseTime: number): void {
    form.setFieldsValue({ reuseTime });
  }

  const title: string = performCreateCustomDeliveryNotes
    ? 'Create custom SIMMA delivery note(s)'
    : 'Edit SIMMA delivery note';

  return (
    <Topbar>
      <Card title={title}>
        <Space direction='vertical'>
          <Space direction='horizontal'>
            {performCreateCustomDeliveryNotes ? (
              <div>
                Select amount of delivery notes:&nbsp;
                <Select
                  defaultValue={`${amountOfDeliveryNotes}`}
                  onChange={AmountOfDeliveryNotes_onClick}
                >
                  <Option value='1'>1</Option>
                  <Option value='2'>2</Option>
                  <Option value='3'>3</Option>
                  <Option value='5'>5</Option>
                  <Option value='10'>10</Option>
                </Select>
              </div>
            ) : (
              <Button type='primary' onClick={() => Save_onClick()}>
                Save
              </Button>
            )}
          </Space>

          <Divider />

          <Form form={form}>
            <Space direction='horizontal'>
              <Form.Item name='DeliveryNoteNumber' label='DELIVERY NOTE NUMBER'>
                <Input
                  onChange={(e: any) =>
                    DeliveryNoteNumber_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item name='LicensePlate' label='LICENSE PLATE'>
                <Input
                  onChange={(e: any) => LicensePlate_onChange(e.target.value)}
                />
              </Form.Item>

              <Form.Item name='LoadingTime' label='LOADING TIME'>
                <DatePicker
                  onChange={(date: any, dateString: string) =>
                    LoadingTime_onChange(date?.toDate())
                  }
                  showTime
                />
              </Form.Item>
            </Space>
            <Space direction='horizontal'>
              <Form.Item name='QuantityAmount' label='QUANTITY'>
                <Input
                  onChange={(e: any) => QuantityAmount_onChange(e.target.value)}
                />
              </Form.Item>

              <Form.Item name='QuantityUnit' label='UNIT'>
                <Input
                  onChange={(e: any) => QuantityUnit_onChange(e.target.value)}
                />
              </Form.Item>

              <Form.Item name='PlantNumber' label='PLANTNO'>
                <Input
                  onChange={(e: any) => PlantNumber_onChange(e.target.value)}
                />
              </Form.Item>

              <Form.Item name='PlantDescription' label='PLANTDESC'>
                <Input
                  onChange={(e: any) =>
                    PlantDescription_onChange(e.target.value)
                  }
                />
              </Form.Item>
            </Space>

            <Divider>Concrete information</Divider>

            <Space direction='vertical'>
              <Form.Item name='ConcreteTypeNumber' label='CONCRETE TYPE NUMBER'>
                <Input
                  onChange={(e: any) =>
                    ConcreteTypeNumber_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item
                name='compressiveStrengthClass'
                label='COMPRESSIVE STRENGTH CLASS'
              >
                <Input
                  onChange={(e: any) =>
                    compressiveStrengthClass_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item
                name='strengthDevelopment'
                label='STRENGTH DEVELOPMENT'
              >
                <Input
                  onChange={(e: any) =>
                    strengthDevelopment_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item name='ExposureClasses' label='EXPOSURE CLASSES'>
                <Input
                  onChange={(e: any) =>
                    ExposureClasses_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item
                name='methodOfConcreteUseChlorideContent'
                label='METHOD OF CONCRETE USE / CHLORIDE CONTENT'
              >
                <Input
                  onChange={(e: any) =>
                    methodOfConcreteUseChlorideContent_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item name='consistencyClass' label='CONSISTENCY CLASS'>
                <Input
                  onChange={(e: any) =>
                    consistencyClass_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item
                name='waterCementRatio'
                label='WATER / CEMENT RATIO (EQ)'
              >
                <Input
                  onChange={(e: any) =>
                    waterCementRatio_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item
                name='maxAggregateSizeGrainSize'
                label='MAX AGGREGATE SIZE / GRAIN SIZE'
              >
                <Input
                  onChange={(e: any) =>
                    maxAggregateSizeGrainSize_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item name='moistureClass' label='MOISTURE CLASS'>
                <Input
                  onChange={(e: any) => moistureClass_onChange(e.target.value)}
                />
              </Form.Item>

              <Form.Item name='specialProperties' label='SPECIAL PROPERTIES'>
                <Input
                  onChange={(e: any) =>
                    specialProperties_onChange(e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item name='mixRemark' label='MIXREMARK'>
                <Input
                  onChange={(e: any) => mixRemark_onChange(e.target.value)}
                />
              </Form.Item>

              <Form.Item name='reuseTime' label='REUSE TIME'>
                <Input
                  onChange={(e: any) => reuseTime_onChange(e.target.value)}
                />
              </Form.Item>
            </Space>
          </Form>
        </Space>

        <Divider>Concrete load quantity overview</Divider>

        <Space direction='vertical'>
          <Form form={formMaterials}>
            <MaterialsList />
          </Form>

          <Space direction='horizontal'>
            {performCreateCustomDeliveryNotes ? (
              <Button
                type='primary'
                onClick={() => CreateCustomDeliveryNotes_onClick()}
              >
                Create delivery note(s)
              </Button>
            ) : (
              <Button type='primary' onClick={() => Save_onClick()}>
                Save
              </Button>
            )}
          </Space>
        </Space>
      </Card>
    </Topbar>
  );
};

export default SimmaDeliveryNote;
