/* tslint:disable */



export enum SimmaOrderStatus {

  /**
   * 0 Order confirmed by dispatch - Message from dispatcher
   * 1 Order change not confirmed by dispatch
   * 5 We cannot accept order because there are not enough trucks available  -  Message from dispatcher
   * 6 New order not edited yet
   * -1 Order cannot be saved because of missing/wrong information  -  Error message
   */

  CONFIRMED = '0',
  UPDATE_WAITING_FOR_CONFIRMATION = '1',
  REJECTED = '5',
  NEW_ORDER_PENDING = '6',
  INVALID = '-1',
  UNKNOWN_CODE = '-99'

}

export interface VendorOrderProd {
  prodtype: number;
  productno: string;
  description: string;
  specqty: number;
  prodqty: number;
  produnit: string;
  dischqty: number;
  vehtype: string;
  ispremotar: boolean;
  issurplus: boolean;
}

export interface VendorOrder {
  custid: string;
  projid: string;
  orderprod: VendorOrderProd[];
}

export interface SimmaRequestOrder {
  $id: string;
  status: SimmaOrderStatus;
  msg: string;
  orderid: string;
  cancelled: string;
  usc: string;
  orderbarcode: string;
  custorderno: string;
  custorderer: string;
  custphone: string;
  projname: string;
  projname1: string;
  projname2: string;
  projstreet: string;
  projcountrycode: string;
  projpostcode: string;
  projcity: string;
  projsec: string;
  projpart: string;
  projgpsposx: number;
  projgpsposy: number;
  startdate: Date;
  qtytotal: string;
  qtyunit: string;
  pumptype: string;
  deliverytype: string;
  remqty: string;
  remark: string;
  dispogroup: string;
  orderint: VendorOrder;
  orderext: Map<string, string>[];

  createdOn: Date;
  lastModified: Date;
  id: string;

  activeVersion: SimmaRequestOrder;

}


export interface ServiceSimmaRequestOrder extends SimmaRequestOrder { }
