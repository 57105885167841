import { getToken, updateToken } from '../providers/IdentityProvider';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

let axiosInstance: AxiosInstance;

export function getAxiosClient(): AxiosInstance {
  if (axiosInstance) {
    return axiosInstance;
  }

  const axiosConfig: AxiosRequestConfig = {
    baseURL: '',
    validateStatus: (status) => status < 600,
  };
  axiosInstance = axios.create(axiosConfig);

  axiosInstance.interceptors.request.use(async (axiosInterceptorConfig) => {
    await updateToken();
    const token = getToken();
    axiosInterceptorConfig.headers.Authorization = 'Bearer ' + token;
    return axiosInterceptorConfig;
  });

  return axiosInstance;
}
