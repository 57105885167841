import { Button, Card, Space } from 'antd';
import { Link } from 'react-router-dom';

const SupplierSelection = (): JSX.Element => {
  return (
    <Card title='Please select the concrete supplier'>
      <Space direction='horizontal'>
        <Link to={`/mocks/simma/orders`}>
          <Button>SIMMA</Button>
        </Link>

        <Link to={`/mocks/sauter/deliverynotes`}>
          <Button>Sauter</Button>
        </Link>
      </Space>
    </Card>
  );
};

export default SupplierSelection;
