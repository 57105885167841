import { Space } from 'antd';
import ProjectUserTable from './components/ProjectUserTable';

const ProjectManagement = (): JSX.Element => {

    return (

        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <ProjectUserTable></ProjectUserTable>
        </Space>
    );
};

export default ProjectManagement;


