import { Button, Card, Col, Divider, Form, Input, Row, Space } from 'antd';
import { useContext } from 'react';
import ErrorNotification from 'src/components/Notification/ErrorNotification';
import SuccessNotification from 'src/components/Notification/SuccessNotification';
import { ToolAdminContext } from 'src/providers/AdminToolProvider';

const SyncProject = (): JSX.Element => {

    const { operationalToolClient } = useContext(ToolAdminContext);

    const [form] = Form.useForm();


    async function refreshProjectOrdersAndDeliveries() {

        try {
            const {
                projectId,
                from,
                to,
            } = await form.validateFields();


            operationalToolClient.refreshAllForProject(projectId, from, to)
            .then((response) => {
                if (response.isSuccess()) {
                    
                    SuccessNotification({
                        message: response.getEntity().message,
                        description: ''
                    });


                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message , description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
        }
        
        catch (e: any) {
            ErrorNotification({
                message: 'Form not filled correctly',
                description: 'Please, make sure that all the fields are filled correctly'
            });
        }

    }

    
    async function refreshProjectOrders() {

        try {
            const {
                projectId,
                from,
                to,
            } = await form.validateFields();


            operationalToolClient.refreshOrdersForProject(projectId, from, to)
            .then((response) => {
                if (response.isSuccess()) {
                    
                    SuccessNotification({
                        message: response.getEntity().message,
                        description: ''
                    });


                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message , description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
        }
        
        catch (e: any) {
            ErrorNotification({
                message: 'Form not filled correctly',
                description: 'Please, make sure that all the fields are filled correctly'
            });
        }

    }


    return (
            <Card title='Sync project orders and deliveries with supplier'>

                <Form form={form}>

                    <Row gutter={[16, 16]}>

                        <Col>
                            <Form.Item name={'projectId'} 
                                label="project Id"
                                rules={[
                                    {
                                        required: true,
                                        pattern: new RegExp(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi),
                                        message: (
                                            <span data-testid="errorId">
                                                Please insert a valid value
                                            </span>
                                        )
                                    }
                                ]}
                            >
                                <Input
                                    style={{ width: 300 }} 
                                    placeholder={''}
                                />
                            </Form.Item>

                        </Col>

                        <Col>
                            <Form.Item name={'from'} 
                                label="From"
                                rules={[
                                    {
                                        required: true,
                                        message: (
                                            <span data-testid="errorFrom">
                                                This field is required
                                            </span>
                                        )
                                    }
                                ]}
                            >
                                <Input
                                    type='number'
                                    style={{ width: 300 }} 
                                />
                            </Form.Item>

                        </Col>

                        <Col>
                            <Form.Item name={'to'} 
                                label="To"
                                rules={[
                                    {
                                        required: true,
                                        message: (
                                            <span data-testid="errorTo">
                                                This field is required
                                            </span>
                                        )
                                    }
                                ]}>

                                <Input
                                    style={{ width: 300 }} 
                                    type='number'
                                />
                            </Form.Item>

                        </Col>

                    </Row>

                    <Divider />

                    <Row>
                        <Space direction='horizontal'>
                            <Button type='primary' onClick={() => refreshProjectOrders()}>
                                Sync project orders
                            </Button>

                            <Button type='primary' onClick={() => refreshProjectOrdersAndDeliveries()}>
                                Sync projects orders and deliveries
                            </Button>
                        </Space>
                    </Row>
                    
                </Form>
            </Card>
            
    );
};

export default SyncProject;

