import { Button, Divider, Space } from 'antd';
import { Link } from 'react-router-dom';

import { FunctionComponent } from 'react';

const TopBar: FunctionComponent<{}> = (props) => {
  return (
    <div>
      <Space direction='horizontal'>
        <Link to={`/supplier/dsa`}>
          <Button>DSA</Button>
        </Link>
        <Divider />
      </Space>
      <Space direction='horizontal'>
        <Link to={`/supplier/godel`}>
          <Button>Godel</Button>
        </Link>
      </Space>
      {props.children}
    </div>
  );
};

export default TopBar;
