import { Button, Card, Col, Divider, Form, Input, Row } from 'antd';
import { useContext, useState } from 'react';
import ErrorNotification from 'src/components/Notification/ErrorNotification';
import SuccessNotification from 'src/components/Notification/SuccessNotification';
import { ToolAdminContext } from 'src/providers/AdminToolProvider';

const CreateOrganization = (): JSX.Element => {

    const [organizationId, setOrganizationId] = useState('');
    const { operationalToolClient } = useContext(ToolAdminContext);

    const [form] = Form.useForm();


    async function createOrganization() {

        try {
            const {
                organizationName,
            } = await form.validateFields();

            operationalToolClient.createOrganization({ name: organizationName })
                .then((response) => {
                    if (response.isSuccess()) {

                        setOrganizationId(response.getEntity().id);

                        SuccessNotification({
                            message: 'organization created successfully',
                            description: ''
                        });

                    } else {
                        const msg = JSON.parse(response.getError());
                        ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                    }
                })
                .catch((info: any) => {
                    ErrorNotification({
                        message: info,
                        description: ''
                    });
                });
        }

        catch (e: any) {
            ErrorNotification({
                message: 'Form not filled correctly',
                description: 'Please, make sure that all the fields are filled correctly'
            });
        }

    }

    return (
        <Card title='Create organization'>

            <Form form={form}>

                <Row gutter={[16, 16]}>

                    <Col>
                        <Form.Item name={'organizationName'}
                            label="Organization Name"
                            rules={[
                                {
                                    required: true,
                                    pattern: new RegExp(/^[^\s]+(\s+[^\s]+)*$/),
                                    message: (
                                        <span data-testid="errorOrganizationName">
                                            Please insert a valid value
                                        </span>
                                    )
                                }
                            ]}>

                            <Input
                                style={{ width: 300 }}
                                placeholder={''}
                            />
                        </Form.Item>

                    </Col>

                </Row>

                {organizationId === '' ? null : <Row>
                    <label>Organization created with id : {organizationId}</label>
                </Row>}

                <Divider />

                <Row>

                    <Button type='primary' onClick={() => createOrganization()}>
                        Create organization
                    </Button>

                </Row>
            </Form>
        </Card>

    );
};

export default CreateOrganization;

