import { useContext, useEffect, useState } from 'react';
import Topbar from '../components/TopBar';

import { Button, Card, Col, DatePicker, Divider, Form, Input, message, Row, Space } from 'antd';
import { Link } from 'react-router-dom';

import SauterDeliveryNotesTable from '../components/SauterDeliveryNotesTable';

import { SauterDeliveryNotesTableRecord } from '../models/SauterDeliveryNotesTableRecord';

import { Config, ConfigContext } from '../providers/ConfigProvider';

import {
    createDefaultDeliveryNotes, getDeliveryNotes
} from '../services/mbk-simma-mock/sauter/DeliveryNoteServices/DeliveryNoteServices';
import { ServiceDeliveryNote } from '../services/mbk-simma-mock/sauter/DeliveryNoteServices/models/ServiceDeliveryNote';

import moment from 'moment';


let savedOrderId: string = '';
let savedFromDate: Date;
let savedToDate: Date;

const SauterDeliveryNotes = (): JSX.Element => {
    const config: Config = useContext(ConfigContext);
    const apiPath: string = `${config.mbkHost}${config.mbkHostApi}`;

    const [orderId, setOrderId] = useState<string>(savedOrderId);
    const [fromDate, setFromDate] = useState<Date>(savedFromDate);
    const [toDate, setToDate] = useState<Date>(savedToDate);

    const [tableRecords, setTableRecords] = useState<
        SauterDeliveryNotesTableRecord[]
    >([]);

    useEffect(() => {
        (async () => {
            refreshDeliveryNotesTable(mapDeliveryNotes(await loadDeliveryNotes()));
        })();
    }, [orderId, fromDate, toDate]);

    async function loadDeliveryNotes(): Promise<ServiceDeliveryNote[]> {
        const minDate: Date = fromDate ? fromDate : new Date('2020-01-01');
        const maxDate: Date = toDate ? toDate : new Date('2100-01-01');

        minDate.setUTCHours(0);
        minDate.setUTCMinutes(0);
        maxDate.setUTCHours(0);
        maxDate.setUTCMinutes(0);
        const deliveryNotes: ServiceDeliveryNote[] = await getDeliveryNotes(
            apiPath,
            orderId,
            minDate,
            maxDate
        );

        return deliveryNotes;
    }

    function mapDeliveryNotes(
        deliveryNotes: ServiceDeliveryNote[]
    ): SauterDeliveryNotesTableRecord[] {
        const newTableRecords: SauterDeliveryNotesTableRecord[] = [];

        for (const deliveryNote of deliveryNotes) {
            newTableRecords.push({
                key: deliveryNote.id,

                orderId: deliveryNote.baustbezeichnung4 || '',
                deliveryNoteId: deliveryNote.id,
                licensePlate: deliveryNote.kennzeichen || '',
                deliveryNoteNumber: String(deliveryNote.lsnummerfremd),
                quantity: `${deliveryNote.lademenge} ${String(deliveryNote.mengeeinheit) === '4' ? '㎥' : '?'
                    }`,
                loadingTime: deliveryNote.zeitstart.slice(0, 5),
            });
        }

        return newTableRecords;
    }

    function refreshDeliveryNotesTable(
        records: SauterDeliveryNotesTableRecord[]
    ): void {
        setTableRecords(records);
    }

    async function loadAndRefresh(): Promise<void> {
        try {
            refreshDeliveryNotesTable(mapDeliveryNotes(await loadDeliveryNotes()));

            message.success('Data loaded');
        } catch (error) {
            message.error(`Data loading failed with error: ${error}`);
        }
    }

    async function CreateDefaultDeliveryNotes_onClick(): Promise<void> {
        try {
            await createDefaultDeliveryNotes(apiPath, orderId);

            message.success('Default delivery notes created');

            loadAndRefresh();
        } catch (error) {
            message.error(
                `Creating default delivery notes failed with error: ${error}`
            );
        }
    }

    function OrderId_onChange(value: string): void {
        setOrderId(value);
        savedOrderId = value;
    }

    function FromDate_onChange(date: Date): void {
        setFromDate(date);
        savedFromDate = date;
    }

    function ToDate_onChange(date: Date): void {
        setToDate(date);
        savedToDate = date;
    }

    function GetDeliveryNote_onClick(): void {
        loadAndRefresh();
    }

    const [form] = Form.useForm();

    return (
        <Topbar>
            <Card title='Sauter delivery notes'>

                <Form
                    form={form}
                    initialValues={
                        {
                            orderId: orderId,
                            ...(fromDate && { fromDate: moment(fromDate) }),
                            ...(toDate && { toDate: moment(toDate) }),
                        }
                    }>

                    <Space direction='vertical'>

                        <Form.Item name={'orderId'} >
                            <Input
                                addonBefore='ORDER ID'
                                onChange={(e) => OrderId_onChange(e.target.value)}
                                value={orderId}
                            />
                        </Form.Item>

                        <Divider />

                        <Space direction='horizontal'>
                            <Button
                                type='primary'
                                onClick={() => CreateDefaultDeliveryNotes_onClick()}
                            >
                                Create default delivery notes (count = 5)
                            </Button>

                            <Link onClick={(event: any) => {
                                if (!orderId) {
                                    alert('ORDER ID is empty!');
                                }
                            }
                            } to={`/mocks/sauter/order/${orderId}/deliverynotes/create`}>
                                <Button type='primary'>Create custom delivery note(s)</Button>
                            </Link>
                        </Space>

                        <Divider />

                        <Row gutter={[16, 16]}>
                            <Col>
                                FROM
                            </Col>
                            <Col>
                                <Form.Item name={'fromDate'} >
                                    <DatePicker
                                        onChange={(date: any, dateString: string) =>
                                            FromDate_onChange(date?.toDate())
                                        }
                                        format='DD.MM.YYYY'
                                        value={fromDate ? moment(fromDate) : undefined}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                TO
                            </Col>
                            <Col>
                                <Form.Item name={'toDate'} >
                                    <DatePicker
                                        onChange={(date: any, dateString: string) =>
                                            ToDate_onChange(date?.toDate())
                                        }
                                        format='DD.MM.YYYY'
                                        value={toDate ? moment(toDate) : undefined}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider />

                        <Button type='primary' onClick={() => GetDeliveryNote_onClick()}>
                            Get delivery notes
                        </Button>

                        <SauterDeliveryNotesTable dataSource={tableRecords} />
                    </Space>
                </Form>
            </Card>
        </Topbar>
    );
};

export default SauterDeliveryNotes;
