import { Button, Card, Col, Divider, Form, Input, Row } from 'antd';
import { useContext } from 'react';
import ErrorNotification from 'src/components/Notification/ErrorNotification';
import SuccessNotification from 'src/components/Notification/SuccessNotification';
import { ToolAdminContext } from 'src/providers/AdminToolProvider';

const UpdateConcreteLoadOrderId = (): JSX.Element => {

    const { operationalToolClient } = useContext(ToolAdminContext);

    const [form] = Form.useForm();


    async function updateConcreteLoadOrderId() {

        try {
            const {
                companyId,
                projectId,
                concreteLoadId,
                newOrderId
            } = await form.validateFields();

            operationalToolClient.updateConcreteLoadOrderId(companyId, projectId, concreteLoadId, newOrderId)
            .then((response) => {
                if (response.isSuccess()) {

                    SuccessNotification({
                        message: 'order Id updated successfully',
                        description: ''
                    });

                } else {
                    const msg = JSON.parse(response.getError());
                    let errorMsg : string = msg.error?.message ?? msg.message;
                    if(errorMsg.includes('Context is not valid. MarketId is probably missing')) {
                        errorMsg = 'ID_NOT_FOUND, Invalid project Id or company Id';
                    }

                    ErrorNotification({ message: errorMsg , description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
        }
        
        catch (e: any) {
            ErrorNotification({
                message: 'Form not filled correctly',
                description: 'Please, make sure that all the fields are filled correctly'
            });
        }

    }

    return (
            <Card title='Change order Id from concrete load'>

                <Form form={form}>

                    <Row gutter={[16, 16]}>

                        <Col>
                            <Form.Item name={'companyId'} 
                                label="Company Id"
                                rules={[
                                    {
                                        required: true,
                                        pattern: new RegExp(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi),
                                        message: (
                                            <span data-testid="errorCompanyId">
                                                Please insert a valid value
                                            </span>
                                        )
                                    }
                                ]}>

                                <Input
                                    style={{ width: 300 }} 
                                    placeholder={''}
                                />
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item name={'projectId'} 
                                label="Project Id"
                                rules={[
                                    {
                                        required: true,
                                        pattern: new RegExp(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi),
                                        message: (
                                            <span data-testid="errorProjectId">
                                                Please insert a valid value
                                            </span>
                                        )
                                    }
                                ]}>

                                <Input
                                    style={{ width: 300 }} 
                                    placeholder={''}
                                />
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item name={'concreteLoadId'} 
                                label="Concrete Load Id"
                                rules={[
                                    {
                                        required: true,
                                        pattern: new RegExp(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi),
                                        message: (
                                            <span data-testid="errorConcreteLoadId">
                                                Please insert a valid value
                                            </span>
                                        )
                                    }
                                ]}>

                                <Input
                                    style={{ width: 300 }} 
                                    placeholder={''}
                                />
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item name={'newOrderId'} 
                                label="New order Id"
                                rules={[
                                    {
                                        required: true,
                                        pattern: new RegExp(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi),
                                        message: (
                                            <span data-testid="errorNewOrderId">
                                                Please insert a valid value
                                            </span>
                                        )
                                    }
                                ]}>

                                <Input
                                    style={{ width: 300 }} 
                                    placeholder={''}
                                />
                            </Form.Item>

                        </Col>
                    </Row>


                    <Divider />

                    <Row>

                        <Button type='primary' onClick={() => updateConcreteLoadOrderId()}>
                            Update order Id
                        </Button>

                    </Row>
                </Form>
            </Card>
            
    );
};

export default UpdateConcreteLoadOrderId;

