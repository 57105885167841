import { DsaGetOrderTicketsResponse } from '@nexploretechnology/concreting-core-client';
import { Button, Card, Col, Divider, Form, Input, Row } from 'antd';
import { useContext, useState } from 'react';
import ErrorNotification from 'src/components/Notification/ErrorNotification';
import { ToolAdminContext } from 'src/providers/AdminToolProvider';

const GetDeliveryNotes = (): JSX.Element => {
  const [supplierProjectId, setSupplierProjectId] = useState('');
  const [supplierOrderId, setSupplierOrderId] = useState('');

  const [form] = Form.useForm();
  const [deliveryNotes, setDeliveryNotes] =
    useState<DsaGetOrderTicketsResponse>();

  const { operationalToolClient } = useContext(ToolAdminContext);

  const getDeliveryNotes = async () => {
    await operationalToolClient
      .DsaGetDeliveryNotes(supplierProjectId, supplierOrderId)
      .then((response) => {
        if (response.isSuccess()) {
          setDeliveryNotes(response.getEntity());
        } else {
          const msg = JSON.parse(response.getError());
          ErrorNotification({
            message: msg.error?.message ?? msg.message,
            description: '',
          });
        }
      })
      .catch((info: any) => {
        ErrorNotification({
          message: info,
          description: '',
        });
      });
  };

  return (
    <Card title="Get Delivery Notes">
      <Form form={form} initialValues={{ supplierProjectId }}>
        <Row gutter={[16, 16]}>
          <Col>
            <Form.Item name={'supplier_project_id'}>
              <Input
                addonBefore="Supplier Project ID"
                onChange={(e) => setSupplierProjectId(e.target.value)}
                value={supplierProjectId}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name={'supplier_order_id'}>
              <Input
                addonBefore="Supplier Order ID"
                onChange={(e) => setSupplierOrderId(e.target.value)}
                value={supplierOrderId}
              />
            </Form.Item>
          </Col>
          <Col>
            <Button type="primary" onClick={() => getDeliveryNotes()}>
              Get Delivery Notes
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider />
      {deliveryNotes && <p>{JSON.stringify(deliveryNotes)}</p>}
    </Card>
  );
};

export default GetDeliveryNotes;
