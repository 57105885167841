import { Space } from 'antd';
import CreateApiAccess from './components/createApiAccess';
import UpdateApiAccess from './components/updateApiAccess';
import CompanyTopBar from 'src/components/TopBar/CompanyTopBar';

const ApiManagement = (): JSX.Element => {

    return (
        <CompanyTopBar>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <CreateApiAccess></CreateApiAccess>
            <UpdateApiAccess></UpdateApiAccess>
        </Space>
        </CompanyTopBar>
    );
};

export default ApiManagement;


