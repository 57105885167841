import { DsaGetSubscriptionsResponse } from '@nexploretechnology/concreting-core-client';
import { Button, Card, Col, Divider, Form, Input, Row } from 'antd';
import { useContext, useState } from 'react';
import ErrorNotification from 'src/components/Notification/ErrorNotification';
import { ToolAdminContext } from 'src/providers/AdminToolProvider';

const CreateSubscription = (): JSX.Element => {
  const [supplierProjectId, setSupplierProjectId] = useState('');
  const [form] = Form.useForm();
  const [subscriptions, setSubscriptions] =
    useState<DsaGetSubscriptionsResponse>();

  const { operationalToolClient } = useContext(ToolAdminContext);

  const createSubscription = async () => {
    await operationalToolClient
      .DsaCreateSubscription(supplierProjectId)
      .then((response) => {
        if (response.isSuccess()) {
          setSubscriptions(response.getEntity());
        } else {
          const msg = JSON.parse(response.getError());
          ErrorNotification({
            message: msg.error?.message ?? msg.message,
            description: '',
          });
        }
      })
      .catch((info: any) => {
        ErrorNotification({
          message: info,
          description: '',
        });
      });
  };

  return (
    <Card title="Create Subscription">
      <Form form={form} initialValues={{ supplierProjectId }}>
        <Row gutter={[16, 16]}>
          <Col>
            <Form.Item name={'user'}>
              <Input
                addonBefore="Supplier Project ID"
                onChange={(e) => setSupplierProjectId(e.target.value)}
                value={supplierProjectId}
              />
            </Form.Item>
          </Col>
          <Col>
            <Button type="primary" onClick={() => createSubscription()}>
              Create Subscription
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider />
      {subscriptions && <p>{JSON.stringify(subscriptions)}</p>}
    </Card>
  );
};

export default CreateSubscription;
