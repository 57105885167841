import { OperationalToolBasicConcreteLoadData } from '@nexploretechnology/concreting-core-client';
import { ColumnsType } from 'antd/lib/table';

export const orderConcreteLoadsColumns: () => ColumnsType<OperationalToolBasicConcreteLoadData>  = () => {

    return [

        {
            title: 'Concrete load Id',
            dataIndex: 'id',
            key: 'id',
        },

        {
            title: 'Delivery Note id',
            dataIndex: 'deliveryNoteId',
            key: 'deliveryNoteId',
        },

        {
            title: 'Delivery Note number',
            dataIndex: 'deliveryNoteNumber',
            key: 'deliveryNoteNumber',
        },


        {
            title: 'Delivery Status',
            dataIndex: 'deliveryStatus',
            key: 'deliveryStatus',
        },

    ];
};

