import { Button, Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import { FunctionComponent } from 'react';

import { formatDate } from '../../providers/ConfigProvider';

import { SimmaOrdersTableRecord } from '../../models/SimmaOrdersTableRecord';

function getSimmaOrderStatusColor(statusText: string): string {
  switch (statusText) {
    case 'CANCELLED':
      return 'yellow';
    case 'CONFIRMED':
      return 'green';
    case 'UPDATE_WAITING_FOR_CONFIRMATION':
      return 'blue';
    case 'REJECTED':
      return 'red';
    case 'NEW_ORDER_PENDING':
      return 'black';
    case 'INVALID':
    case 'UNKNOWN_CODE':
      return 'cyan';
  }
  return 'cyan';
}

function getSimmaOrderStatusText(
  status: string,
  record: SimmaOrdersTableRecord
): string {
  switch (status) {
    case '0': {
      if (record.cancelled) {
        return 'CANCELLED';
      }
      return 'CONFIRMED';
    }
    case '1':
      return 'UPDATE_WAITING_FOR_CONFIRMATION';
    case '5':
      return 'REJECTED';
    case '6':
      return 'NEW_ORDER_PENDING';
    case '-1':
      return 'INVALID';
    case '-99':
      return 'UNKNOWN_CODE';
  }
  return '?';
}

const SimmaOrdersTable: FunctionComponent<{
  dataSource: SimmaOrdersTableRecord[];
  cancelSimmaOrder: (orderId: string, orderNumber: string) => void;
}> = (props) => {
  const records: SimmaOrdersTableRecord[] = props.dataSource;

  const cancelSimmaOrder = props.cancelSimmaOrder;

  const columns: ColumnsType<SimmaOrdersTableRecord> = [
    {
      title: 'Delivery time',
      dataIndex: 'deliveryTime',
      key: 'Delivery time',
      render: (value: any, record: SimmaOrdersTableRecord) => (
        <Space direction='vertical'>{formatDate(record.deliveryTime)}</Space>
      ),
    },
    {
      title: 'Order number',
      dataIndex: 'orderNumber',
      key: 'Order number',
      render: (value: any, record: SimmaOrdersTableRecord) => (
        <Space direction='vertical'>{record.orderNumber?.toUpperCase()}</Space>
      ),
    },
    {
      title: 'Concrete supplier order id',
      dataIndex: 'supplierOrderid',
      key: 'Concrete supplier order id',
    },
    {
      title: 'Concrete type',
      dataIndex: 'concreteType',
      key: 'Concrete type',
    },
    {
      title: 'Quantity/output',
      dataIndex: 'Quantity/output',
      key: 'Quantity/output',
      render: (value: any, record: SimmaOrdersTableRecord) => (
        <Space direction='vertical'>
          {`${record.quantity} / `}
          {record.performance}
        </Space>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'Status',
      render: (value: any, record: SimmaOrdersTableRecord) => (
        <Tag
          color={`${getSimmaOrderStatusColor(
            getSimmaOrderStatusText(record.status, record)
          )}`}
        >
          {getSimmaOrderStatusText(record.status, record)}
        </Tag>
      ),
    },
    {
      title: 'Change order',
      dataIndex: 'Change order',
      key: 'Change order',
      render: (value: any, record: SimmaOrdersTableRecord) => (
        <Space size='middle'>
          <Link to={`/mocks/simma/order/${record.orderId}`}>
            <Button type='link'>Edit</Button>
          </Link>

          <Button
            type='link'
            onClick={() => cancelSimmaOrder(record.orderId, record.orderNumber)}
          >
            Cancel
          </Button>
        </Space>
      ),
    },
    {
      title: 'Delivery notes',
      dataIndex: 'Delivery notes',
      key: 'Delivery notes',
      render: (value: any, record: SimmaOrdersTableRecord) => (
        <Link to={`/mocks/simma/order/${record.orderId}/deliverynotes`}>
          <Button type='link'>View</Button>
        </Link>
      ),
    },
  ];

  if (!records.length) {
    return <h1 data-testid='no-table' aria-hidden='true'></h1>;
  }

  return (
    <div data-testid='table'>
      <Table columns={columns} dataSource={records} />
    </div>
  );
};

export default SimmaOrdersTable;
