import { Direction, OffsetPagination, ProjectUser } from '@nexploretechnology/concreting-core-client';
import { Button, Card, Col, Form, Input, Row, Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { useContext, useState } from 'react';
import ErrorNotification from 'src/components/Notification/ErrorNotification';
import { ToolAdminContext } from 'src/providers/AdminToolProvider';
import { projectUsersColumns } from './ProjectUserTable.columns';


const ProjectUserTable = () => {
    const [projectId, setProjectId] = useState();
    const [members, setMembers] = useState([] as ProjectUser[]);
    const [totalMembers, setTotalMembers] = useState<number>(0);
    const [tableLoading, setTableLoading] = useState<boolean>(false);

    const pageSize: number = 10;

    const pagination = {
        position: ['bottomRight'],
        pageSize: pageSize,
        total: totalMembers
    } as TablePaginationConfig;
    let sorting: Partial<Record<keyof ProjectUser, Direction>>;

    const { operationalToolClient } = useContext(ToolAdminContext);
    
    const [form] = Form.useForm();

    async function onChange(
        paginationOnChange: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<ProjectUser> | SorterResult<ProjectUser>[],
        extra: TableCurrentDataSource<ProjectUser>
    ) {
        console.log(sorter);
        sorter = sorter as SorterResult<ProjectUser>;

        if (sorter.columnKey && sorter.order) {
            const sortDirection: string = { ascend: 'asc', descend: 'desc' }[sorter.order];
            const sortingValue: string = `{"${sorter.columnKey}": "${sortDirection || 'DESC'}"}`;
            sorting = JSON.parse(sortingValue);
        }

        const queryParams: OffsetPagination<ProjectUser> = {
            skip:
                paginationOnChange.current! === 0
                    ? 0
                    : (paginationOnChange.current! - 1) * paginationOnChange.pageSize!, 
            limit: paginationOnChange?.pageSize!,
            sorting: sorting ? [sorting] : []
        };

        loaddProjectMembers(projectId, queryParams);
    }

    async function validateForm() {

        try {
            const {
                projectId,
            } = await form.validateFields();
            
            setProjectId(projectId);
            loaddProjectMembers(projectId);
        }
        
        catch (e: any) {
            ErrorNotification({
                message: 'Form not filled correctly',
                description: 'Please, make sure that all the fields are filled correctly'
            });
        }

    }

    const loaddProjectMembers = async (projectId: string, queryParams?: OffsetPagination<ProjectUser>) => {
        
        const paginationParam: OffsetPagination<ProjectUser> = {
            skip: queryParams ? queryParams.skip : 0,
            limit: queryParams ? queryParams.limit : pageSize,
            sorting: queryParams && queryParams.sorting ? queryParams.sorting : []
        };

        setTableLoading(true);
        operationalToolClient
            .loadprojectMembers(projectId, paginationParam)
            .then((response) => {
                if (response.isSuccess()) {
                    setMembers(response.getEntity().data);
                    setTotalMembers(response.getEntity().count);
                    setTableLoading(false);
                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message , description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
    };


    return  (

        <Card title='Load projects members'>

            <Form form={form}>

                <Row gutter={[16, 16]}>

                    <Col>
                        <Form.Item name={'projectId'} 
                            label="Project Id"
                            rules={[
                                {
                                    required: true,
                                    pattern: new RegExp(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi),
                                    message: (
                                        <span data-testid="errorProjectId">
                                            Please insert a valid value
                                        </span>
                                    )
                                }
                            ]}>

                            <Input
                                style={{ width: 300 }} 
                                placeholder={''}
                            />
                        </Form.Item>

                    </Col>
                </Row>

                <Row>

                <Button type='primary' onClick={() => validateForm()}>
                    Get project members
                </Button>

                </Row>

            </Form>

            {!projectId ? null : 
                <Table
                    style={{marginTop: '1.5rem'}}
                    data-testid="project-user-table"
                    rowClassName="projectUserRow"
                    rowKey={'id'}
                    columns={projectUsersColumns()}
                    dataSource={members}
                    loading={tableLoading}
                    onChange={onChange}
                    pagination={pagination}
                />
            }
        </Card>
    );
};

export default ProjectUserTable;
